import urls from "../global/constants/UrlConstants";
import strings from "../global/constants/StringConstants";
import { AuthMap } from "../models/interfaces";
import { store } from "./store";
// import history from "./history";
import { logOutAction } from "../redux/authSlice";

const AuthMapper = {
  Admin: [],
  User: [],
} as AuthMap;

const ListOfMenus = [
  {
    text: "Home",
    key: "home",
    link: urls.calculateViewPath,
    pageName: strings.HOME,
  },
];

export const GenerateMenu = () => {
  const generatedMenu: any[] = [];
  ListOfMenus.forEach((menu: any) => {
    generatedMenu.push(menu);
  });
  return generatedMenu;
};

export const doesUserHasAccessTo = (componentName: string) => {
  // const role = "Admin";
  // if (!doesRoleExistInLocalStorage(role)) {
  //   return [];
  // }
  // const userAuthorizedPages = AuthMapper[role];
  // return userAuthorizedPages.includes(componentName);
  return true;
};

export const generatedSubMenu = (subMenu: any) => {
  const role = "";
  if (!doesRoleExistInLocalStorage(role)) {
    return [];
  }
  const userRole = AuthMapper[role];
  const generatedMenu: any[] = [];
  subMenu.forEach((menu: any) => {
    if (userRole?.includes(menu.pageName)) {
      generatedMenu.push(menu);
    }
  });
  return generatedMenu;
};

export const homePageRedirection = () => {
  return urls.dashboardViewPath;
};

const doesRoleExistInLocalStorage = (role: string) => {
  if (!role) {
    store.dispatch(logOutAction());
    return false;
  }
  return true;
};

const PageMapper = {
  back: [
    strings.HOME,
    strings.DASHBOARD,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  moon: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  profile: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  notification: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
} as AuthMap;

export const doesPageHasComponent = (component: string) => {
  const page = window.location.pathname.split("/")[1].toLowerCase();
  const userAuthorizedComponent = PageMapper[component];
  if (userAuthorizedComponent.includes(page)) return false;
  return true;
};
