import {
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const cardComponentStyles = {
  deleteText: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#8876F0",
  },
  labelText: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
  },
  // planBtn: {
  //   borderRadius: "8px",
  //   background: "#9053F6",
  //   border: "1px solid #9053F6",
  //   color: "#FDFDFD",
  //   ...mediumFont,
  //   fontSize: getRelativeFontSize(0),
  //   minWidth: "180px",
  //   textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
  //   transition: "box-shadow 0.15s ease, transform 0.15s ease",
  //   willChange: "box-shadow,transform",
  //   padding: "10px 15px",

  //   "&:hover": {
  //     boxShadow:
  //       "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
  //     transform: "translateY(-0.1em)",
  //     background: "#9053F6",
  //     color: "#FDFDFD",
  //   },

  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: getRelativeFontSize(0),
  //     minWidth: "150px",
  //   },
  // },
  planBtn: {
    fontSize: getRelativeFontSize(1),
    maxWidth: "fit-content",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(0),
      maxWidth: "100%",
    },
  },
  cardBox: {
    borderRadius: "8px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    padding: "15px 20px",
  },
  selectedCardBox: {
    border: "3px solid #9053f6",
    cursor: "pointer",
  },
  cardText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#FFFFFF",
  },
  primaryText: {
    ...regularFont,
    fontSize: getRelativeFontSize(-1),
    color: "#ba91ff",
  },
  noDataWrapper: {
    borderRadius: "15px",
    border: "3px dashed rgba(255, 255, 255, 0.21)",
    backdropFilter: "blur(24px)",
    textAlign: "center",
    py: 5,
  },
  planText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    color: "#ffffff",
  },
  menuWrapper: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    px: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  btnGrid: {
    ...centerItemFlex,
    px: 1,
    py: 0,
    [theme.breakpoints.down("lg")]: {
      px: 0,
      py: 1,
    },
  },
} as const;

export default cardComponentStyles;
