import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import AnalysisFlipCards from "./AnalysisFlipCards";
import { useTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../../helpers/methods";
import { getAnalysisData } from "../../../Dashboard/DashboardService";

interface CustomProps {
  profileId?: string;
  setIsLoading: Function;
}

const AnalysisCards = (props: CustomProps) => {
  const [analysisData, setAnalysisData] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    getAnalysis();
  }, [i18n.language]);

  // const checkExistence = () => {
  //   const profiles = [...profileDetails];
  //   const existingProfileIndex = profileDetails.findIndex(
  //     (profile) => profile.profileId === props?.profileId!
  //   );

  //   if (
  //     existingProfileIndex !== -1 &&
  //     profiles[existingProfileIndex].analysis
  //   ) {
  //     return profiles[existingProfileIndex].analysis;
  //   }
  //   return false;
  // };

  // const handleRedux = (value: any) => {
  //   const profiles = [...profileDetails];
  //   const existingProfileIndex = profileDetails.findIndex(
  //     (profile) => profile.profileId === props?.profileId!
  //   );

  //   if (checkExistence()) {
  //     profiles[existingProfileIndex] = {
  //       ...profiles[existingProfileIndex],
  //       analysis: value,
  //     };
  //   } else {
  //     const newProfile: any = {
  //       profileId: props?.profileId!,
  //       analysis: value,
  //     };
  //     profiles.push(newProfile);
  //   }

  //   dispatch(
  //     storeProfileDetails({
  //       email,
  //       profiles: profiles,
  //     })
  //   );
  // };

  const getAnalysis = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getAnalysisData(props?.profileId!, language);
      setAnalysisData(response.analysisDetails);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  return (
    <Grid container pl={{ md: 2, xs: 0 }} py={2}>
      {analysisData?.map((data: any) => {
        return <AnalysisFlipCards flipCardData={data} />;
      })}
    </Grid>
  );
};

export default AnalysisCards;
