import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import analysisStyles from "../../Analysis.styles";
import { theme } from "../../../../utils/styles";
import moonIcon from "../../../../assets/icons/PlanetsSymbol/moonIcon.svg";
import ascendantIcon from "../../../../assets/icons/PlanetsSymbol/ascendantIcon.svg";
import ketuIcon from "../../../../assets/icons/PlanetsSymbol/ketuIcon.svg";
import rahuIcon from "../../../../assets/icons/PlanetsSymbol/rahuIcon.svg";
import jupiterIcon from "../../../../assets/icons/PlanetsSymbol/jupiterIcon.svg";
import sunIcon from "../../../../assets/icons/PlanetsSymbol/sunIcon.svg";
import marsIcon from "../../../../assets/icons/PlanetsSymbol/marsIcon.svg";
import mercuryIcon from "../../../../assets/icons/PlanetsSymbol/mercuryIcon.svg";
import neptuneIcon from "../../../../assets/icons/PlanetsSymbol/neptuneIcon.svg";
import plutoIcon from "../../../../assets/icons/PlanetsSymbol/plutoIcon.svg";
import saturnIcon from "../../../../assets/icons/PlanetsSymbol/saturnIcon.svg";
import uranusIcon from "../../../../assets/icons/PlanetsSymbol/uranusIcon.svg";
import venusIcon from "../../../../assets/icons/PlanetsSymbol/venusIcon.svg";
import { CustomIcon } from "../../../../global/components";
import LoopIcon from "@mui/icons-material/Loop";
import { useTranslation } from "react-i18next";

interface Symbols {
  [key: string]: string;
}

interface CustomProps {
  flipCardData: any;
}

const PlanetsFlipCards = (props: CustomProps) => {
  const classes = analysisStyles;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFlipped, setIsFlipped] = useState(false);

  const symbols: Symbols = {
    moon: moonIcon,
    sun: sunIcon,
    ascendant: ascendantIcon,
    rahu: rahuIcon,
    ketu: ketuIcon,
    jupiter: jupiterIcon,
    mars: marsIcon,
    mercury: mercuryIcon,
    neptune: neptuneIcon,
    pluto: plutoIcon,
    saturn: saturnIcon,
    uranus: uranusIcon,
    venus: venusIcon,
  };

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  function convertToDegreesMinutesSeconds(encodedString: string) {
    const element = document.createElement("div");
    element.innerHTML = encodedString;
    let visibleText = element.innerText;
    visibleText = visibleText.replace(
      /[^a-zA-Z0-9\s]/g,
      (match) => `${match} `
    );

    return visibleText;
  }

  return (
    <Grid item xl={4} lg={6} md={12} sm={12} xs={12} height={"100%"} p={1}>
      <Stack
        direction={"row"}
        className={isFlipped ? "is-flipped" : ""}
        sx={classes.planetCardStyle}
        spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
        // onClick={handleCardClick}
      >
        {/* {!isFlipped &&
          (isMobile ? (
            <img
              src={symbols[props.flipCardData.symbol]}
              alt="Module not found..."
              style={{ width: "40px" }}
            />
          ) : (
            <img
              src={symbols[props.flipCardData.symbol]}
              alt="Module not found..."
              style={{ width: "80px" }}
            />
          ))} */}

        {!isFlipped ? (
          <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
            {/* <Typography sx={classes.labelTypo}>
              {props.flipCardData.point_name}
            </Typography>
            <Divider sx={{ border: "1px solid #FFFFFF", width: "30%" }} /> */}
            <Stack direction={"row"} sx={classes.planetNameBox}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <img
                  src={symbols[props.flipCardData.point_name.toLowerCase()]}
                  alt="Module not found..."
                  style={{ width: "40px" }}
                />
                <Typography sx={classes.valueTypo}>
                  {props.flipCardData.point_name}
                </Typography>
              </Stack>
              <Typography sx={classes.valueTypo}>
                {convertToDegreesMinutesSeconds(
                  props.flipCardData.position_dms
                )}
              </Typography>
            </Stack>
            <Stack direction={"column"} spacing={1} px={2} pb={1}>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("nakshatra")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.nakshatra_key}.{props.flipCardData.name}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("nakshatraLord")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.nakshatra_lord}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("pada")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.pada}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("padaSign")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.pada_sign}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("padaLord")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.pada_lord}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("sign")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.sign_name}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("signLord")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.sign_lord}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("house")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.house_number}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("houseNakshatra")}:
                <Typography
                  display="inline-block"
                  sx={classes.valueTypo}
                  pl={0.5}
                >
                  {props.flipCardData.house_nakshatra_name}
                </Typography>
              </Box>
              <Box sx={classes.labelTypo} display="inline-block">
                {t("relationship")}:
                <Typography
                  display="inline-block"
                  sx={
                    props.flipCardData.dignities &&
                    props.flipCardData.mooltrikona &&
                    props.flipCardData.relationship
                      ? { ...classes.valueTypo, fontSize: "14px" }
                      : classes.valueTypo
                  }
                  pl={0.5}
                >
                  {[
                    props.flipCardData.dignities &&
                      `${props.flipCardData.dignities}'s House`,
                    props.flipCardData.mooltrikona &&
                      props.flipCardData.mooltrikona,
                    props.flipCardData.relationship &&
                      `${props.flipCardData.relationship} Rashi`,
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </Typography>
              </Box>
            </Stack>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={classes.valueTypo}>
                {props.flipCardData.zodiac}
              </Typography>
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
            </Box> */}
          </Stack>
        ) : (
          <Stack direction={"column"} spacing={1} sx={classes.flipCardContent}>
            <Typography sx={classes.flipLabelType}>
              {props.flipCardData.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
              <Typography sx={classes.valueTypo}></Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default PlanetsFlipCards;
