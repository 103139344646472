import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getInnerDashaData = async (innerDashaObject: any) => {
  try {
    const callParams = await getCallParams("POST", innerDashaObject);
    const response = await makeCall(urls.innerDashaCosmicData, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getBuyNowUrl = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.buyNowUrl, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getPlanetData = async (profileId: string, language: string) => {
  const url = `${urls.planetData}/${profileId}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
