import {
  boldFont,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const loginStyles = {
  scrollElement: {
    // height: "calc(100vh - 225px)",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 220px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 245px)",
    // },
  },
  label: { display: "flex", height: "20px" },
  labelText: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  textRadious: {
    borderRadius: "100px",
  },
  getHeading: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(18),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(14),
    },
  },
  getHeading2: {
    ...mediumFont,
    color: "rgba(255, 255, 255, 0.27)",
    fontSize: getRelativeFontSize(18),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(14),
    },
  },
  welcomeTypo: {
    ...regularFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(7),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  textField: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #FFF",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(5.5px)",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      "&::placeholder": {
        ...regularFont,
        color: "#FFFFFF",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#FFFFFF",
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
  },
  emailTextField: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #FFF",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(5.5px)",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#939393",
      "&::placeholder": {
        ...regularFont,
        color: "#939393",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#939393",
      "&.Mui-focused fieldset": {
        borderColor: "#939393",
        // border: 0,
      },
    },
  },
  loginBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#9053F6",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    "&:disabled": {
      background: "#c09aff",
    },
  },
  loginBtnTypo: {
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  otpBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#FDFDFD",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #9053F6",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
      color: "#9053F6",
    },
  },
  otpBtnTypo: {
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  otpInput: {
    borderRadius: "10px",
    background: "#F0F0F0",
    height: "50px",
    width: "50px",
    fontSize: "25px",
    textAlign: "center",
    marginRight: "15px",
    outline: "0",
    border: "0",
    margin: "0 10px",
  },
  dialogTitle: {
    ...mediumFont,
    color: "#C2C2C2",
    fontSize: getRelativeFontSize(5),
  },
  dialogSubTypo: {
    ...mediumFont,
    color: "#222222",
    fontSize: getRelativeFontSize(2),
  },
  lightTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "rgba(34, 34, 34, 0.70)",
  },
  loadingStyle: {
    color: "#000000",
    width: "25px !important",
    height: "25px !important",
  },
  noteTypo: {
    ...regularFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(2),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  getLoginScreen: {
    py: 2,
    display: "grid",
    alignItems: "center",
  },
  innerGetLoginBox: { maxWidth: "100%", maxHeight: "100%" },
  signBtn: {
    width: "100%",
  },
  errorStyling: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  searchPlaceInput: {
    ...mediumFont,
    padding: "12px 10px",
    width: "-webkit-fill-available",
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    outline: "none",
    color: "#FFFFFF",
    zIndex: 1,
  },
  nameField: {
    ...mediumFont,
    color: "#FFFFFF",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  modalHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(8),
    color: "#222222",
    textAlign: "center",
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  lightFont: {
    fontSize: getRelativeFontSize(2),
    ...regularFont,
    color: "#666666",
  },
  cancelBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(3),
    },
  },
  cancelBtnBox: {
    background: "#FFFFFF",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  logoutBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color: "#FFFFFF",
      background: "#FFFFFF",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(5),
    },
  },
} as const;

export default loginStyles;
