import { CustomDialog } from "../../../../global/components";
import {
  handleErrorMessage,
  openSuccessNotification,
} from "../../../../helpers/methods";
import deletePaymentMethodDialogStyles from "./DeletePaymentMethodDialog.styles";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { deletePaymentMethod } from "./DeletePaymentMethodDialogService";
import { getRelativeFontSize } from "../../../../utils/styles";
import { useTranslation } from "react-i18next";

interface CustomProps {
  setIsLoading: Function;
  handleClose: Function;
  setPaymentMethods: Function;
  open: boolean;
  selectedPaymentMethod: string;
  selectedCard: any;
}

const DeletePaymentMethodDialog = (props: CustomProps) => {
  const classes = deletePaymentMethodDialogStyles;
  const { t } = useTranslation();

  const handleDeletePaymentMethod = async (paymentMethodId: string) => {
    try {
      props.setIsLoading(true);
      const response = await deletePaymentMethod(paymentMethodId);
      props.setPaymentMethods(response.paymentMethods);
      openSuccessNotification(response.message);
      props.handleClose();
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {t("deletePaymentMethodHeader")}
        </Typography>

        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} pb={5} spacing={2} sx={classes.dialogContent}>
        <Typography
          sx={{
            ...classes.lightFont,
            textAlign: "center",
            fontSize: getRelativeFontSize(5),
          }}
          pt={2}
        >
          xxxx xxxx xxxx {props?.selectedCard?.last4}
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={2}
          alignSelf={"center"}
        >
          <Button onClick={() => props.handleClose()} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText"> {t("no")}</Typography>
            </Box>
          </Button>
          <Button
            onClick={() =>
              handleDeletePaymentMethod(props.selectedPaymentMethod)
            }
            sx={classes.logoutBtn}
          >
            <Typography className="btnText"> {t("yes")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };
  const getDeletePaymentMethodDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={props.open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={() => props.handleClose()}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };
  return getDeletePaymentMethodDialog();
};

export default DeletePaymentMethodDialog;
