import React, { useEffect, Fragment } from "react";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";

interface CustomProps {
  children: React.ReactNode;
}

const ScrollToTop = ({
  history,
  children,
}: RouteComponentProps & CustomProps) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

const ScrollToTopRoute = withRouter(ScrollToTop);

export default ScrollToTopRoute;
