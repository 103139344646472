import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import cardComponentStyles from "./CardComponent.styles";
import { useEffect, useState } from "react";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import {
  getPayPalUrl,
  getPaymentMethodDetails,
  makeDefaultPaymentMethod,
} from "./CardComponentService";
import {
  getCardSymbols,
  handleErrorMessage,
  openSuccessNotification,
} from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import DeletePaymentMethodDialog from "../Dialogs/DeletePaymentMethodDialog/DeletePaymentMethodDialog";
import AddCardDialog from "../Dialogs/AddCardDialog/AddCardDialog";
import noData from "../../../assets/images/noData.svg";
import paypalIcon from "../../../assets/icons/paypalIcon.svg";
import addCardIcon from "../../../assets/icons/addCardIcon.svg";
import darkAddCardIcon from "../../../assets/icons/darkAddCardIcon.svg";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../global/components";
import strings from "../../../global/constants/StringConstants";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";

interface CustomProps {
  setIsLoading: Function;
  setPaymentMethodId: Function;
  selection?: boolean;
  priceId?: string;
}

const CardComponent = (props: CustomProps) => {
  const classes = cardComponentStyles;
  const { t } = useTranslation();
  const [openAddCard, setOpenAddCard] = useState<boolean>(false);
  const [openDeletePaymentMethod, setOpenDeletePaymentMethod] =
    useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  const [selectedCard, setSelectedCard] = useState();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getPaymentMethods();
  }, [defaultPaymentMethod]);

  const getPaymentMethods = async () => {
    try {
      props.setIsLoading(true);
      const paymentMethods = await getPaymentMethodDetails();
      // setDefaultPaymentMethod(
      //   paymentMethods?.find((method: any) => method.default === true)?.id
      // );
      if (props.selection) {
        setSelectedPaymentMethod(
          paymentMethods?.find((method: any) => method.default === true)?.id
        );
        props.setPaymentMethodId(
          paymentMethods?.find((method: any) => method.default === true)?.id
        );
      }
      setPaymentMethods(paymentMethods);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  function handleRedirect(url: string) {
    window.open(url, "_blank");
    history.push(urls.subscriptionViewPath);
  }

  const redirectToPayPal = async () => {
    try {
      props.setIsLoading(true);
      const response = await getPayPalUrl(props.priceId!);
      handleRedirect(response.url);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      props.setIsLoading(true);
      const response = await makeDefaultPaymentMethod(paymentMethodId);
      openSuccessNotification(response.message);
      setDefaultPaymentMethod(paymentMethodId);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClose = () => {
    setOpenAddCard(false);
    setOpenDeletePaymentMethod(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleDefaultPaymentMethod(selectedPaymentMethod)}
        >
          <ListItemIcon>
            <TaskAltOutlinedIcon htmlColor="#8876F0" />
          </ListItemIcon>
          <Typography sx={classes.deleteText}>{t("makeDefault")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setOpenDeletePaymentMethod(true)}>
          <ListItemIcon>
            <DeleteOutlineRoundedIcon htmlColor="#8876F0" />
          </ListItemIcon>
          <Typography sx={classes.deleteText}>{t("delete")}</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const getCardComponent = () => {
    return (
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pb={2}
        >
          <Typography sx={classes.labelText}>
            {props.selection ? t("payUsing") : t("cardDetails")}
          </Typography>
          {!props.selection && (
            <CustomButton
              label={t("addCard")}
              type={strings.SECONDARY}
              onClick={() => {
                setOpenAddCard(true);
              }}
              customClasses={classes.planBtn}
              icon={
                <img
                  src={darkAddCardIcon}
                  style={{ height: "20px", marginRight: "5px" }}
                  alt="Module Not Found..."
                />
              }
            />
          )}
        </Stack>

        {paymentMethods.length > 0 ? (
          paymentMethods.map((methods: any) => {
            return (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={
                  props.selection
                    ? selectedPaymentMethod === methods.id
                      ? { ...classes.cardBox, ...classes.selectedCardBox }
                      : { ...classes.cardBox, cursor: "pointer" }
                    : classes.cardBox
                }
                mb={1}
                onClick={() => {
                  setSelectedPaymentMethod(methods.id);
                  props.setPaymentMethodId(methods.id);
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <img
                    src={getCardSymbols(methods.brand)}
                    height={"25px"}
                    style={{ borderRadius: "5px" }}
                    alt="Module Not Found..."
                  />
                  <Typography sx={classes.cardText} px={1}>
                    xxxx xxxx xxxx {methods.last4}
                  </Typography>
                  <Typography sx={classes.primaryText}>
                    {methods.default && `★ ${t("primary")}`}
                  </Typography>
                </Stack>
                {!methods.default && (
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setSelectedPaymentMethod(methods.id);
                      setSelectedCard(methods);
                      handleClick(event);
                    }}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    sx={{ p: 0 }}
                  >
                    <PendingOutlinedIcon htmlColor="#FFFFFF66" />
                  </IconButton>
                )}
              </Stack>
            );
          })
        ) : (
          <>
            {!props.selection && (
              <Box sx={classes.noDataWrapper}>
                <img src={noData} alt="Module Not Found..." />
                <Typography sx={classes.planText}> {t("noCard")}!</Typography>
              </Box>
            )}
          </>
        )}
        {props.selection && (
          <Grid container pt={2} justifyContent={"center"}>
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              sx={classes.btnGrid}
            >
              <CustomButton
                label={t("paypal")}
                type={strings.SECONDARY}
                onClick={redirectToPayPal}
                icon={
                  <img
                    src={paypalIcon}
                    style={{ height: "25px", marginRight: "5px" }}
                    alt="Module Not Found..."
                  />
                }
                customClasses={classes.planBtn}
              />
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              sx={classes.btnGrid}
            >
              <CustomButton
                label={t("addCard")}
                type={strings.SECONDARY}
                onClick={() => {
                  setOpenAddCard(true);
                }}
                customClasses={classes.planBtn}
                icon={
                  <img
                    src={darkAddCardIcon}
                    style={{ height: "20px", marginRight: "5px" }}
                    alt="Module Not Found..."
                  />
                }
              />
            </Grid>
          </Grid>
        )}
        {getMenu()}
        {getDialogs()}
      </Stack>
    );
  };

  const getDialogs = () => {
    return (
      <>
        <AddCardDialog
          setIsLoading={props.setIsLoading}
          handleClose={handleClose}
          open={openAddCard}
          setPaymentMethods={setPaymentMethods}
        />
        <DeletePaymentMethodDialog
          setIsLoading={props.setIsLoading}
          handleClose={handleClose}
          open={openDeletePaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          setPaymentMethods={setPaymentMethods}
          selectedCard={selectedCard}
        />
      </>
    );
  };

  return getCardComponent();
};

export default CardComponent;
