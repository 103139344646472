import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import subscriptionStyles from "./Subscriptions.styles";
import { handleErrorMessage } from "../../helpers/methods";
import { useState, useEffect } from "react";
import he from "he";
import { AppPlans } from "../../models/interfaces";
import notifiers from "../../global/constants/NotificationConstants";
import {
  getInvoiceDetails,
  getSubscriptionDetails,
} from "./SubscriptionsService";
import { CustomButton, CustomTable } from "../../global/components";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import tickMarkIcon from "../../assets/icons/tickMarkIcon.svg";
import ChangePlanDialog from "./Components/ChangePlanDialog";
import CancelSubscriptionDialog from "./Components/CancelSubscriptionDialog";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import CardComponent from "../Shared/CardComponent/CardComponent";
import noSubscription from "../../assets/images/noSubscription.svg";
import { useTranslation } from "react-i18next";
import refreshIcon from "../../assets/icons/refreshIcon.svg";
import { useAppDispatch } from "../../utils/hooks";
import { subscriptionAction } from "../../redux/authSlice";
import strings from "../../global/constants/StringConstants";
import history from "../../utils/history";
import urls from "../../global/constants/UrlConstants";

const Subscriptions = () => {
  const classes = subscriptionStyles;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [myPlan, setMyPlan] = useState<AppPlans>({
    planId: "",
    priceId: "",
    price: "",
    duration: "",
    amount: 0,
    currency: "",
    name: "",
    isRecommended: false,
    details: "",
    keyFeatures: [],
    end: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState([]);
  const [openCancelPlan, setOpenCancelPlan] = useState<boolean>(false);
  const [openChangePlan, setOpenChangePlan] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const invoiceHeader = [
    {
      name: `${t("invoice")}`,
      field: "number",
    },
    {
      name: `${t("amount")}`,
      field: "subTotal",
    },
    {
      name: `${t("planType")}`,
      field: "planType",
    },
    {
      name: `${t("startDate")}`,
      field: "start",
    },
    {
      name: `${t("endDate")}`,
      field: "end",
    },
    {
      name: `${t("status")}`,
      field: "status",
    },
    {
      name: `${t("action")}`,
      field: "action",
    },
  ];

  useEffect(() => {
    getSubscriptionsData(page, rowsPerPage);
  }, [page, i18n.language, rowsPerPage, refresh]);

  const getSubscriptionsData = async (page: number, rowsPerPage: number) => {
    try {
      setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const [planDetails, invoice] = await Promise.all([
        getSubscriptionDetails(language),
        getInvoiceDetails(page, rowsPerPage),
      ]);
      setMyPlan(planDetails.planDetails);
      dispatch(subscriptionAction({ isSubscribed: planDetails.isSubscribed }));
      convertDataForTable(invoice.invoices);
      setCount(invoice.totalData);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const convertDataForTable = (invoice: any) => {
    const tableValue = invoice.map((item: any) => {
      return {
        id: item.id,
        link: item.link,
        currency: item.currency,
        number: item.number,
        subTotal: item.subTotal,
        planType: item.planType,
        start: item.start,
        end: item.end,
        status: item.status,
        action: (
          <Box onClick={() => window.open(item.link, "_blank")}>
            <CloudDownloadRoundedIcon htmlColor="#8876F0" />
          </Box>
        ),
      };
    });
    setInvoices(tableValue);
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpenCancelPlan(false);
    setOpenChangePlan(false);
  };

  const getMySubscription = () => {
    return (
      <Stack direction={"column"} px={{ sm: 5, xs: 1 }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent={"space-between"}
          alignItems={{ md: "center", xs: "flex-start" }}
          pb={1}
          mb={3}
          sx={{ borderBottom: "1px solid #FFFFFF66" }}
        >
          <Typography pb={{ md: 0, xs: 1 }} sx={classes.headerText}>
            {t("mySubscription")}
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            <img src={refreshIcon} alt="Module Not Found..." />
            <Typography pl={1} sx={classes.cardText}>
              {t("refresh")}
            </Typography>
          </Stack>
        </Stack>

        <Grid container pb={4}>
          <Grid
            item
            xl={5}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            pr={{ lg: 3, xs: 0 }}
          >
            <Box sx={classes.planBox}>
              <Typography sx={classes.planDescText}>
                ★ {t("yourPlan")}
              </Typography>
            </Box>
            <Box sx={classes.priceBox}>
              {myPlan.planId ? (
                <Stack direction={"column"} alignItems={"center"}>
                  <Typography sx={classes.planText} pt={1} pb={2}>
                    {myPlan?.name}
                  </Typography>
                  <Typography sx={classes.planDescText} pb={4}>
                    {myPlan?.details}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} pb={4}>
                    <Typography sx={classes.priceText}>
                      {he.decode(myPlan?.price!)}
                    </Typography>
                    <Typography pl={1} sx={classes.durationText}>
                      /{myPlan?.duration}
                    </Typography>
                  </Box>
                  <Typography sx={classes.planDescText} pb={4}>
                    {t("expiresOn")} {myPlan?.end}
                  </Typography>
                  <Grid container pb={4} sx={{ px: "5%" }}>
                    {myPlan?.keyFeatures?.map((point: string) => {
                      return (
                        <Grid item p={1.5}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            <img
                              src={tickMarkIcon}
                              alt="Module Not Found..."
                              height={"12px"}
                            />
                            <Typography sx={{ ...classes.planDescText, px: 0 }}>
                              {point}
                            </Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    spacing={2}
                    pb={3}
                  >
                    <Grid item>
                      <Button
                        sx={classes.cancelSubBtn}
                        onClick={() => setOpenCancelPlan(true)}
                      >
                        {t("cancelSubscription")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={classes.planBtn}
                        onClick={() => setOpenChangePlan(true)}
                      >
                        {t("changePlan")}
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Box textAlign={"center"} p={2}>
                  <img src={noSubscription} alt="Module Not Found..." />
                  <Typography sx={classes.planText} pb={4}>
                    {t("noPlan")}!
                  </Typography>
                  <CustomButton
                    label={t("subscribeHeader")}
                    type={strings.SECONDARY}
                    customClasses={{ maxWidth: "fit-content" }}
                    onClick={() => history.push(urls.pricingViewPath)}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xl={7}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            pl={{ lg: 3, xs: 0 }}
            pt={{ lg: 0, xs: 3 }}
          >
            <CardComponent
              setIsLoading={setIsLoading}
              setPaymentMethodId={() => {}}
            />
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const getTable = () => {
    return (
      <Box px={{ sm: 5, xs: 0 }}>
        <CustomTable
          headers={invoiceHeader}
          rows={invoices}
          isRowPerPageEnable={true}
          pageNumber={page}
          handlePageChange={handleChangePage}
          paginationCount={count}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    );
  };

  const getDialogs = () => {
    return (
      <>
        <ChangePlanDialog
          setIsLoading={setIsLoading}
          handleClose={handleClose}
          open={openChangePlan}
        />
        <CancelSubscriptionDialog
          setIsLoading={setIsLoading}
          handleClose={handleClose}
          open={openCancelPlan}
        />
      </>
    );
  };

  const getSubscriptions = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              {getMySubscription()}
              {getTable()}
              {getDialogs()}
            </Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };
  return getSubscriptions();
};

export default Subscriptions;
