import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getSubscriptionDetails = async (language: string) => {
  const url = `${urls.getSubscription}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceDetails = async (page: number, rowsPerPage: number) => {
  const url = `${urls.getInvoices}/${rowsPerPage}/${page}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const callParams = await getCallParams("DELETE");
    const response = await makeCall(urls.cancelSubscription, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
