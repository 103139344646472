import {
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import contactUsDialogStyles from "./ContactUsDialog.styles";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "../../../../global/components";
import strings from "../../../../global/constants/StringConstants";
import { useState } from "react";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import { contactUs } from "./ContactUsDialogService";
import CustomSelect from "../../../../global/components/CustomSelect/CustomSelect";

interface ContactUs {
  [key: string]: {
    value: string;
    error: any;
  };
}

interface CustomProps {
  setIsLoading: Function;
  setOpenContactUs: Function;
  handleClose: Function;
  openContactUs: boolean;
}

const ContactUsDialog = (props: CustomProps) => {
  const classes = contactUsDialogStyles;
  const { t } = useTranslation();
  const [contact, setContact] = useState<ContactUs>({
    reason: {
      value: "0",
      error: "",
    },
    message: {
      value: "",
      error: "",
    },
  });

  const reasonMenu = [
    { value: "0", content: `${t("selectReason")}`, disabled: true },
    { value: `${t("reason1")}`, content: `${t("reason1")}` },
    { value: `${t("reason2")}`, content: `${t("reason2")}` },
    { value: `${t("reason3")}`, content: `${t("reason3")}` },
  ];

  const formValidation = (contact: any) => {
    let errors = contact;
    let isValid = true;
    const reason = contact.reason.value;
    const message = contact.message.value;
    if (reason === "0") {
      errors.reason.error = "Please select reason";
      isValid = false;
    }
    if (!message) {
      errors.message.error = "Please enter message";
      isValid = false;
    }
    return { isValid, errors };
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(contact);
    setContact({ ...errors });
    return isValid;
  };

  const handleContactUs = async () => {
    try {
      if (handleValidation()) {
        props.setIsLoading(true);
        const response = await contactUs(
          contact.reason.value,
          contact.message.value
        );
        openSuccessNotification(response.message);
        props.setOpenContactUs(false);
        props.setIsLoading(false);
      }
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  // const handleValidation = (isPromo: boolean) => {
  //   if (contact.reason.value === "0") {
  //     setContact({
  //       ...contact,
  //       reason: {
  //         value: contact.reason.value,
  //         error: "Please select reason",
  //       },
  //     });
  //     return false;
  //   }
  //   if (!contact.message.value) {
  //     setContact({
  //       ...contact,
  //       message: {
  //         value: contact.message.value,
  //         error: "Please enter message",
  //       },
  //     });
  //     return false;
  //   }
  //   return true;
  // };

  const handleFieldChange = (event: any) => {
    setContact({
      ...contact,
      [event.target.name]: {
        ...contact[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.contactUsHeader} px={2}>
          {t("contactHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        pb={5}
        spacing={2}
        sx={classes.dialogContent}
      >
        <CustomSelect
          menuItems={reasonMenu}
          onChange={handleFieldChange}
          id={"reason"}
          name={"reason"}
          customClasses={classes.textField}
          value={contact.reason.value}
          error={contact.reason.error}
        />
        {/* <Select
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          sx={classes.textField}
          name="reason"
          value={contact.reason.value}
          onChange={handleFieldChange}
        >
          <MenuItem value={"0"} selected disabled>
            {t("selectReason")}
          </MenuItem>
          <MenuItem value={t("reason1")}>{t("reason1")}</MenuItem>
          <MenuItem value={t("reason2")}>{t("reason2")}</MenuItem>
          <MenuItem value={t("reason3")}>{t("reason3")}</MenuItem>
        </Select> */}
        <CustomInput
          name="message"
          id="message"
          placeHolder="Type here..."
          multiline
          rows={6}
          value={contact.message.value}
          customClasses={classes.textField}
          onChange={handleFieldChange}
          error={!isTruthy(contact.message.value) && contact.message.error}
        />
        {/* <TextField
          name="message"
          id="message"
          placeholder="Type here..."
          multiline
          rows={6}
          sx={classes.textField}
          onChange={handleFieldChange}
        /> */}
        <CustomButton
          label={t("contact")}
          type={strings.PRIMARY}
          onClick={handleContactUs}
          customClasses={{ maxWidth: "250px" }}
        />
      </Stack>
    );
  };

  const getContactUsDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={props.openContactUs}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={props.handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  return getContactUsDialog();
};

export default ContactUsDialog;
