import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dashboardStyles from "./Dashboard.styles";
import plusIcon from "../../assets/icons/plusIcon.svg";
import gradientPlusIcon from "../../assets/icons/gradientPlusIcon.svg";
import whiteEditIcon from "../../assets/icons/whiteEditIcon.svg";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  selectIsSubscribed,
  selectProfileCount,
  subscriptionAction,
} from "../../redux/authSlice";
import { useEffect, useState } from "react";
import history from "../../utils/history";
import urls from "../../global/constants/UrlConstants";
import { CustomDialog } from "../../global/components";
import {
  handleErrorMessage,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../helpers/methods";
import { getProfile, makeDefault, setPromoCodeValue } from "./DashboardService";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { useTranslation } from "react-i18next";
import defaultProfileIcon from "../../assets/icons/defaultProfileIcon.svg";
import regularProfileIcon from "../../assets/icons/regularProfileIcon.svg";
import ContactUsDialog from "../Shared/Dialogs/ContactUsDialog/ContactUsDialog";

interface ProfileDetails {
  profileId: string;
  name: string;
  birthDate: string;
  default: boolean;
}

interface PromoCode {
  value: string;
  error: string;
}

const Dashboard = () => {
  const classes = dashboardStyles;
  const dispatch = useAppDispatch();
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const profileCount = useAppSelector(selectProfileCount);
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState<ProfileDetails[]>([]);
  const [blankProfiles, setBlankProfiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPromo, setOpenPromo] = useState<boolean>(false);
  const [openContactUs, setOpenContactUs] = useState<boolean>(false);
  const [openSubscription, setOpenSubscription] = useState<boolean>(false);
  const [defaultProfile, setDefaultProfile] = useState<string>("");
  const [promoCode, setPromoCode] = useState<PromoCode>({
    value: "",
    error: "",
  });

  useEffect(() => {
    !isSubscribed && setOpenSubscription(true);
  }, [isSubscribed]);

  useEffect(() => {
    getProfiles();
  }, [defaultProfile]);

  const getProfiles = async () => {
    try {
      setIsLoading(true);
      const response = await getProfile();
      setProfiles(response);
      const blankProfilesArray = Array.from(
        { length: profileCount - response.length },
        (_, index) => index + 1
      );
      setBlankProfiles(blankProfilesArray);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleDefaultProfile = async (profileId: string) => {
    try {
      setIsLoading(true);
      const response = await makeDefault(profileId);
      setDefaultProfile(profileId);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleValidation = () => {
    if (!promoCode.value) {
      setPromoCode({
        value: promoCode.value,
        error: "Please enter promo code",
      });
      return false;
    }
    return true;
  };

  const handleAddPromoCode = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const response = await setPromoCodeValue(promoCode.value);
        openSuccessNotification(response.message);
        dispatch(subscriptionAction({ isSubscribed: true }));
        setOpenPromo(false);
        setIsLoading(false);
      } else {
        openErrorNotification(promoCode.error);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddPromoCode();
    }
  };

  const handlePromoCode = (event: React.ChangeEvent<any>) => {
    setPromoCode({ value: event.target.value, error: "" });
  };

  const handleClose = () => {
    setOpenPromo(false);
    setOpenContactUs(false);
    setOpenSubscription(false);
    setPromoCode({
      value: "",
      error: "",
    });
  };

  const getSubscriptionButtons = () => {
    return (
      <>
        {!isSubscribed && (
          <Grid
            container
            spacing={4}
            sx={{ maxWidth: "800px", pt: 2 }}
            pb={{ md: 0, xs: 2 }}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box sx={classes.promoCard} onClick={() => setOpenPromo(true)}>
                <Typography sx={classes.headerText}>
                  {t("promoCode")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box
                sx={classes.promoCard}
                onClick={() => history.push(urls.pricingViewPath)}
              >
                <Typography sx={classes.headerText}>
                  {t("subscribe")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const getDashboardHeader = () => {
    return (
      <>
        <Typography sx={classes.italicTypo} pt={{ md: 10, sm: 0, xs: 0 }}>
          {t("dashboardHeader")}
        </Typography>
        <Typography sx={classes.welcomeText} pt={3}>
          {t("dashboardContent")}
        </Typography>
      </>
    );
  };

  const getProfileCards = () => {
    return (
      <>
        <Grid
          container
          sx={{ maxWidth: "1200px" }}
          mt={{ sm: 5, xs: 0 }}
          justifyContent={"center"}
        >
          {profiles.map((profile: any) => {
            return (
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                px={{ sm: 1, xs: 0 }}
                key={profile.profileId}
              >
                <Box sx={classes.defaultProfileBox}>
                  {profile.default ? (
                    <img
                      src={defaultProfileIcon}
                      style={{
                        height: "40px",
                        borderTopLeftRadius: "20px",
                      }}
                      alt="Module Not Found..."
                    />
                  ) : (
                    <img
                      src={regularProfileIcon}
                      style={{
                        height: "40px",
                        borderTopLeftRadius: "20px",
                        cursor: "pointer",
                      }}
                      alt="Module Not Found..."
                      onClick={() =>
                        !isSubscribed
                          ? setOpenSubscription(true)
                          : handleDefaultProfile(profile.profileId)
                      }
                    />
                  )}
                </Box>
                <Box sx={classes.profileCard}>
                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <Box
                      textAlign={"center"}
                      onClick={() =>
                        isSubscribed
                          ? history.push(
                              `${urls.analysisViewPath}?id=${profile.profileId}`
                            )
                          : setOpenSubscription(true)
                      }
                    >
                      <Typography sx={classes.profileName}>
                        {profile.name}
                      </Typography>
                      <Typography sx={classes.profileDOB}>
                        DOB: {profile.birthDate}
                      </Typography>
                      <Divider
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.20)",
                          width: "100%",
                          height: "0px",
                          marginTop: "10px",
                        }}
                      />
                    </Box>
                    <Box
                      onClick={() =>
                        isSubscribed
                          ? history.push(
                              `${urls.profileViewPath}?id=${profile.profileId}`
                            )
                          : setOpenSubscription(true)
                      }
                      sx={classes.centerFlex}
                    >
                      <img
                        src={whiteEditIcon}
                        alt="Module not found..."
                        style={{ width: "20px" }}
                      />
                      <Typography
                        pl={0.5}
                        sx={{
                          ...classes.profileName,
                          textTransform: "capitalize",
                        }}
                      >
                        {t("edit")}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
          {blankProfiles.map((count: number) => {
            return (
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                key={count}
                px={{ sm: 1, xs: 0 }}
              >
                <Box
                  sx={{ ...classes.cardStyle, marginTop: "47px" }}
                  onClick={() =>
                    isSubscribed
                      ? history.push(urls.calculateViewPath)
                      : setOpenSubscription(true)
                  }
                >
                  <img
                    src={plusIcon}
                    alt="Module not found..."
                    style={{ width: "50px" }}
                  />
                  <Typography sx={classes.headerText}>
                    {t("addProfile")}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {profiles.length >= profileCount && (
          <Box
            display={"flex"}
            pt={4}
            sx={{ cursor: "pointer" }}
            onClick={() => setOpenContactUs(true)}
          >
            <img
              src={gradientPlusIcon}
              alt="Module not found..."
              style={{ width: "30px" }}
            />
            <Typography pl={2} sx={classes.gradientTypo}>
              {t("addMoreProfile")}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        {openPromo && (
          <>
            <Typography sx={classes.lightTypo}>{t("promoCode")}</Typography>
            <Divider
              sx={{
                border: "1px solid #E7E7E7",
                width: "80%",
                height: "0px",
              }}
            />
          </>
        )}
        {openSubscription && <></>}
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        pb={5}
        spacing={2}
        sx={classes.dialogContent}
      >
        {openPromo && (
          <>
            <Box mt={2} width={"70%"}>
              <TextField
                sx={classes.promoTextField}
                value={promoCode.value}
                onChange={handlePromoCode}
                onKeyPress={handleKeypress}
              />
              {!isTruthy(promoCode.value) && promoCode.error && (
                <FormHelperText error sx={classes.errorStyling}>
                  {promoCode.error}
                </FormHelperText>
              )}
            </Box>
            <Button
              sx={{ ...classes.loginBtn, minWidth: "280px", mt: 2 }}
              onClick={handleAddPromoCode}
            >
              <Typography sx={{ ...classes.loginBtnTypo, fontSize: "15px" }}>
                {t("submit")}
              </Typography>
            </Button>
          </>
        )}
        {openSubscription && (
          <>
            <Typography sx={classes.subscriptionInfoText}>
              {t("newUserDesc1")}
            </Typography>
            <Typography sx={classes.thankYouText}>{t("thankYou")}!</Typography>
            <Box sx={classes.subscriptionBox}>
              <Typography sx={classes.subscriptionText}>
                {t("newUserDesc2")} 👍
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    );
  };

  const getPromoCodeDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={openPromo}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  const getContactUsDialog = () => {
    return (
      <ContactUsDialog
        setIsLoading={setIsLoading}
        setOpenContactUs={setOpenContactUs}
        handleClose={handleClose}
        openContactUs={openContactUs}
      />
    );
  };

  const getSubscriptionDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={openSubscription}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  const getDashboard = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {getSubscriptionButtons()}
                {getDashboardHeader()}
                {getProfileCards()}
                {getPromoCodeDialog()}
                {getContactUsDialog()}
                {getSubscriptionDialog()}
              </Stack>
            </Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getDashboard();
};

export default Dashboard;
