import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../../utils/styles";

const contactUsDialogStyles = {
  contactUsHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(16),
    color: "#222222",
    textAlign: "center",
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  icon: {
    color: "#ffffff",
  },
  textField: {
    width: "99%",
    background: "transparent",
    borderRadius: "10px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#ffffff,#ffffff),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",

    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#000000",

      "&::placeholder": {
        ...mediumFont,
        color: "##000000de",
      },
    },
    "& .MuiSelect-icon": {
      color: "#000000",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "#ffffff",
      "&.Mui-focused fieldset": {
        borderColor: "#ffffff",
      },
    },
  },
} as const;

export default contactUsDialogStyles;
