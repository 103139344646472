import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import analysisStyles from "../../Analysis.styles";
import { useTranslation } from "react-i18next";
import rahuHouseImage from "../../../../assets/images/rahuHouseSmImage.jpg";
import zodiakuImage from "../../../../assets/images/zodiakuImage.png";
import { theme } from "../../../../utils/styles";

const AnalysisLegends = () => {
  const classes = analysisStyles;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down(650));

  return (
    <Stack direction={"column"}>
      <Grid container sx={classes.legendContainer}>
        <Grid
          item
          xl={5.9}
          lg={5.9}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("ascendantLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("ascendantLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem sx={classes.legendDivider} />
        <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("sunLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>{t("sunLegendDesc")}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid
          item
          xl={5.9}
          lg={5.9}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("moonLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("moonLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            border: "1px solid #ffffff54",
          }}
        />
        <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("ketuLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("ketuLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid
          item
          xl={5.9}
          lg={5.9}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("rahuD1Legend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("rahuD1LegendDesc")}
            </Typography>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            border: "1px solid #ffffff54",
          }}
        />
        <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("rahuD10Legend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("rahuD10LegendDesc")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography pb={2} sx={classes.labelTypo}>
              {t("rashiBhava")}
            </Typography>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("rashiLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("rashiLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} pb={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("bhavaLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("bhavaLegendDesc")}
            </Typography>
            <Typography pt={2} sx={classes.labelTypo}>
              {t("rashiBhavaSummary")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("houseLegend")}
            </Typography>
            <Typography pb={2} sx={classes.labelTypo}>
              {t("houseLegendDesc1")}
            </Typography>
            <Typography pb={2} sx={classes.labelTypo}>
              {t("houseLegendDesc2")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("houseLegendDesc3")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("expectationsLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("expectationsLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("vedicHouseLegend")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("vedicHouseLegendDesc")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={classes.legendContainer}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("rahuKetuHousesLegend")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc1")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc2")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc3")}
            </Typography>
            <Box textAlign={"center"}>
              {isMobile ? (
                <img
                  src={rahuHouseImage}
                  height={"200px"}
                  width={"250px"}
                  alt="Module Not Found..."
                />
              ) : (
                <img
                  src={rahuHouseImage}
                  height={"250px"}
                  width={"300px"}
                  alt="Module Not Found..."
                />
              )}
            </Box>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc4")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc5")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc6")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc7")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("rahuKetuHousesLegendDesc8")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Box
        textAlign={"center"}
        py={2}
        sx={{
          borderBottom: "2px solid transparent",
          borderImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) -0.69%, #ffffff54 50.58%, rgba(255, 255, 255, 0) 99.95%)`,
          borderImageSlice: "1",
        }}
      >
        {isMobile ? (
          <img
            src={zodiakuImage}
            height={"250px"}
            width={"250px"}
            alt="Module Not Found..."
          />
        ) : (
          <img
            src={zodiakuImage}
            height={"500px"}
            width={"500px"}
            alt="Module Not Found..."
          />
        )}
      </Box>
      <Grid container>
        <Grid
          item
          xl={5.9}
          lg={5.9}
          md={12}
          sm={12}
          xs={12}
          sx={classes.legendsGrid}
        >
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("atmaKarakaHeader")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("atmaKarakaDesc")}
            </Typography>
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            border: "1px solid #ffffff54",
          }}
        />
        <Grid item xl={5.9} lg={5.9} md={12} sm={12} xs={12}>
          <Stack direction="column" px={2} py={3}>
            <Typography sx={classes.valueTypo} pb={2}>
              {t("daraKarakaHeader")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("daraKarakaDesc")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AnalysisLegends;
