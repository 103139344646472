import {
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";
import backImage from "../../assets/images/loginBackImage.png";

const privacyPolicyStyles = {
  content: {
    width: "100%",
    minHeight: "calc(100vh - 165px)",
    height: "auto",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      minHeight: "calc(100vh - 100px)",
    },
  },
  // mainContainer: {
  //   display: "flex",
  //   justifyContent: "center",
  //   mx: 5,
  //   [theme.breakpoints.down("sm")]: {
  //     mx: 2,
  //   },
  // },
  // mainBox: {
  //   background: `url(${backImage})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   p: 1,
  //   borderRadius: "20px",
  //   minHeight: "calc(100vh - 220px)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(12px)",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     p: 1,
  //   },
  // },
  // subBox: {
  //   p: 3,
  //   minHeight: "calc(100vh - 260px)",
  //   borderRadius: "20px",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   ...centerItemFlex,
  // },

  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(30px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    marginTop: "25px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 252px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 240px)",
    //   p: 1,
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: "60px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  headingTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
    color: "#ffffff",
  },
  subHeadingTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: "#ffffff",
  },
  normalTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
  },
  linkTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
    cursor: "pointer",
  },
  pointsTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
    display: "list-item",
    listStyle: "disc",
    ml: 2.5,
  },
  innerPointsTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
    display: "list-item",
    listStyleType: "disclosure-closed",
    ml: 4.5,
  },
  decimalPointsTypo: {
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
    ...regularFont,
    ml: 2.5,
    position: "relative",
    paddingLeft: "15px",
  },
  customDecimal: {
    fontSize: getRelativeFontSize(2),
    color: "#ffffffa6",
    position: "absolute",
    left: -20,
  },
  footer: {
    ...centerItemFlex,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    textAlign: "center",
    color: "#FFFFFF",
  },
} as const;

export default privacyPolicyStyles;
