import { purplePrimaryColor } from "../../../utils/styles";
const paginationStyles = {
  pageBtn: {
    display: "flex",
    marginTop: "8px",
    justifyContent: "end",
    background: "#cfb3ff",
    borderRadius: "10px",
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: "#9053f6 !important",
      borderRadius: "50%",
      margin: "5px",
    },
  },
} as const;
export default paginationStyles;
