import { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  handleErrorMessage,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../helpers/methods";
import { setPassword } from "./AuthService";
import urls from "../../global/constants/UrlConstants";
import notifiers from "../../global/constants/NotificationConstants";
import ResetPasswordStyles from "./Auth.styles";
import history from "../../utils/history";
import { theme } from "../../utils/styles";
import {
  resetPasswordForm,
  resetPasswordValidation,
} from "./AuthTypesAndValidation";
import strings from "../../global/constants/StringConstants";
import hidePasswordIcon from "../../assets/images/Hide.svg";
import showPasswordIcon from "../../assets/images/Show.svg";
import { useAppSelector } from "../../utils/hooks";
import { selectTempToken, tempTokenRemoveAction } from "../../redux/authSlice";
import { store } from "../../utils/store";
import { CustomButton, CustomInput } from "../../global/components";
import { useTranslation } from "react-i18next";

interface CustomProps {
  location?: Location;
  isSetPassword?: boolean;
}

const ResetPassword = (props: CustomProps) => {
  const classes = ResetPasswordStyles;
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get("token");
  const token = useAppSelector(selectTempToken);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(resetPasswordForm);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleSetPassword = async () => {
    try {
      if (handleValidation()) {
        if (formFields.password.value === formFields.confirmPassword.value) {
          const userData = {
            password: formFields.password.value,
          };
          setIsLoading(true);
          const response = await setPassword(
            props.isSetPassword ? token : tokenParam!,
            userData
          );
          openSuccessNotification(response.message);
          setIsLoading(false);
          store.dispatch(tempTokenRemoveAction());
          props.isSetPassword && history.push(urls.loginViewPath);
        } else {
          openErrorNotification("Password and Confirm password doesn't match");
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClickShowPassword = (field: string) => {
    if (field === "password") {
      setShowPassword(showPassword);
    } else {
      setShowConfirmPassword(showConfirmPassword);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSetPassword();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = resetPasswordValidation(formFields, false);
    setFormFields({ ...errors });
    return isValid;
  };

  const getResetPasswordScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={classes.getHeading}>
            {props.isSetPassword ? t("setPassword") : t("resetPassword")}
          </Typography>
        </Box>
        <Typography pt={1} sx={classes.welcomeTypo}>
          {t("welcome")}
        </Typography>
        <Box sx={classes.innerGetLoginBox} pt={{ sm: 7, xs: 5 }}>
          <Box mt={2}>
            <CustomInput
              required
              label={
                props.isSetPassword ? t("createPassword") : t("newPassword")
              }
              placeHolder="••••••••"
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formFields.password.value}
              onChange={handleOnChangeInputField}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("password")}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => handleMouseDownPassword(event, "password")}
                      edge="end"
                    >
                      <Box
                        component="img"
                        src={showPassword ? showPasswordIcon : hidePasswordIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                !isTruthy(formFields.password.value) &&
                formFields.password.error
              }
            />
          </Box>
          <Box mt={2}>
            <CustomInput
              required
              label={t("confirmPassword")}
              placeHolder="••••••••"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formFields.confirmPassword.value}
              onChange={handleOnChangeInputField}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confirmPassword")}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => handleMouseDownPassword(event, "confirmPassword")}
                      edge="end"
                    >
                      <Box
                        component="img"
                        src={
                          showConfirmPassword
                            ? showPasswordIcon
                            : hidePasswordIcon
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                !isTruthy(formFields.confirmPassword.value) &&
                formFields.confirmPassword.error
              }
            />
          </Box>

          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label={"Submit"}
              type={strings.SECONDARY}
              loading={isLoading}
              onClick={handleSetPassword}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box px={1} sx={classes.scrollElement}>
      {getResetPasswordScreen()}
    </Box>
  );
};

export default ResetPassword;
