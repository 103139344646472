import {
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const resetPasswordStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    marginTop: "25px",
    maxWidth: "1750px",
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 222px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 252px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    ...centerItemFlex,
    // display: "grid",
    // alignContent: "center",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 240px)",
    //   p: 1,
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 270px)",
    // },
  },
  // mainBox: {
  //   p: 1,
  //   borderRadius: "20px",
  //   height: "calc(100vh - 220px)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   background: "rgba(255, 255, 255, 0.07)",
  //   backdropFilter: "blur(12px)",
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   marginTop: "25px",
  //   maxWidth: "1750px",
  //   width: "100%",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 222px)",
  //   },
  // },
  // subBox: {
  //   p: 3,
  //   height: "calc(100vh - 270px)",
  //   borderRadius: "20px",
  //   overflowY: "auto",
  //   overflowX: "hidden",
  //   "&::-webkit-scrollbar": {
  //     width: "5px",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     borderRadius: "10px",
  //     border: "1px solid #FFFFFF",
  //     background: "#282945",
  //   },
  //   border: "1px solid rgba(255, 255, 255, 0.14)",
  //   [theme.breakpoints.down("sm")]: {
  //     height: "calc(100vh - 240px)",
  //     p: 1,
  //   },
  // },
  textField: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(5.5px)",

    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      "&::placeholder": {
        ...regularFont,
        color: "#FFFFFF",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "rgba(255, 255, 255, 0.21)",
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: getRelativeFontSize(40),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(30),
    },
  },
  label: { display: "flex", height: "20px" },
  labelText: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  errorStyling: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    paddingLeft: "10px",
  },
  submitBtn: {
    py: 1.5,
    borderRadius: "8px",
    background: "#FDFDFD",
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #9053F6",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
      color: "#9053F6",
    },
  },
  submitBtnTypo: {
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  loadingStyle: {
    color: "#000000",
    width: "25px !important",
    height: "25px !important",
  },
} as const;

export default resetPasswordStyles;
