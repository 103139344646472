import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getProfileById = async (id: string) => {
  const url = `${urls.getProfileById}/${id}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateProfile = async (profile: any) => {
  const url = `${urls.updateProfile}/${profile.profileId}`;
  try {
    const callParams = await getCallParams("PUT", profile);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
