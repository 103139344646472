import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import analysisStyles from "./Analysis.styles";
import { useEffect, useState } from "react";
import AnalysisCards from "./Components/Analysis/AnalysisCards";
import { theme } from "../../utils/styles";
import MineralsCards from "./Components/Minerals/MineralsCards";
import Mahadasha from "./Components/Mahadasha/Mahadasha";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { handleErrorMessage } from "../../helpers/methods";
import { useTranslation } from "react-i18next";
import MahadashaDetails from "./Components/Mahadasha/MahadashaDetails";
import dobIcon from "../../assets/icons/dobIcon.svg";
import { getProfileById } from "../Profile/ProfileService";
import { formData } from "../Calculator/CalculatorData";
import strings from "../../global/constants/StringConstants";
import analysisIcon from "../../assets/icons/analysisIcon.svg";
import planetsIcon from "../../assets/icons/planetsIcon.svg";
import missingMineralsIcon from "../../assets/icons/missingMineralsIcon.svg";
import mahadashaIcon from "../../assets/icons/mahadashaIcon.svg";
import activeAnalysisIcon from "../../assets/icons/activeAnalysisIcon.svg";
import activePlanetsIcon from "../../assets/icons/activePlanetsIcon.svg";
import activeMissingMineralsIcon from "../../assets/icons/activeMissingMineralsIcon.svg";
import activeMahadashaIcon from "../../assets/icons/activeMahadashaIcon.svg";
import selectedTabIcon from "../../assets/icons/selectedTabIcon.svg";
import AnalysisLegends from "./Components/Analysis/AnalysisLegends";
import MineralsLegends from "./Components/Minerals/MineralsLegends";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomIcon } from "../../global/components";
import RaysLegends from "./Components/Analysis/RaysLegend";
import Planets from "./Components/Planets/Planets";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";

interface CustomProps {
  location?: any;
  selectedLanguage: string;
}

const Analysis = (props: CustomProps) => {
  const classes = analysisStyles;
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(strings.ANALYSIS_TAB);
  const [selectedSubTab, setSelectedSubTab] = useState<string>(strings.DATA);
  const [selectedLegend, setSelectedLegend] = useState<string>("");
  const [selectedHouse, setSelectedHouse] = useState<string>("D1");
  const [formFields, setFormFields] = useState(formData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   if (!isFirstRender.current) {
  //     redirectionState?.profileData && handleCalculate();
  //   } else {
  //     isFirstRender.current = false;
  //   }
  // }, [i18n.language]);

  const mainTabConfig = [
    {
      tabName: strings.ANALYSIS_TAB,
      activeIcon: activeAnalysisIcon,
      icon: analysisIcon,
      header: "analysisHeader",
    },
    {
      tabName: strings.MINERALS_TAB,
      activeIcon: activeMissingMineralsIcon,
      icon: missingMineralsIcon,
      header: "mineralsHeader",
    },
    {
      tabName: strings.MAHADASHA_TAB,
      activeIcon: activeMahadashaIcon,
      icon: mahadashaIcon,
      header: "mahadashaHeader",
    },
    {
      tabName: strings.PLANET_TAB,
      activeIcon: activePlanetsIcon,
      icon: planetsIcon,
      header: "planetHeader",
    },
  ];

  const houseMenu = [
    {
      value: "D1",
      content: "D1",
    },
    {
      value: "D2",
      content: "D2",
    },
    {
      value: "D9",
      content: "D9",
    },
    {
      value: "D10",
      content: "D10",
    },
  ];

  useEffect(() => {
    id && getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getProfileById(id!);
      setFormFields({
        firstName: {
          value: response.firstName,
          error: "",
        },
        lastName: {
          value: response.lastName,
          error: "",
        },
        bloodGroup: {
          value: response.bloodGroup,
          error: "",
        },
        sex: {
          value: response.sex,
          error: "",
        },
        birthDate: {
          value: response.birthDate,
          error: "",
        },
        birthTime: {
          value: response.birthTime,
          error: "",
        },
        birthPlace: {
          value: response.birthPlace,
          error: "",
        },
        pregnancy: {
          value: response.pregnancy,
          error: "",
        },
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleTabChange = (tabValue: string) => {
    setSelectedTab(tabValue);
    setSelectedSubTab(strings.DATA);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleHouseChange = (event: SelectChangeEvent<string>) => {
    setSelectedHouse(event.target.value);
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setSelectedLegend(strings.PLANET);
            setSelectedSubTab("Legends");
          }}
        >
          <Typography sx={classes.menuItem}>{t("planetLegend")}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedLegend(strings.RAYS);
            setSelectedSubTab("Legends");
          }}
        >
          <Typography sx={classes.menuItem}>{t("raysLegend")}</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const getProfileData = () => {
    return (
      <Grid
        container
        justifyContent={{ xl: "center", sm: "flex-start", xs: "center" }}
        px={{ sm: 5, xs: 2 }}
        pb={{ sm: 1, xs: 0 }}
        pt={1}
      >
        <Grid
          item
          px={{ sm: 1, xs: 0 }}
          py={{ sm: 0.5, xs: 0 }}
          pt={{ sm: 0, xs: 1 }}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            spacing={{ sm: 1, xs: 0 }}
            alignItems={"center"}
          >
            {!isTablet && (
              <img src={dobIcon} height={"35px"} alt="Module Not Found..." />
            )}
            <Typography
              sx={{ ...classes.cardLabel, textDecoration: "underline" }}
            >
              {t("birthDetails")}:
            </Typography>
            <Typography sx={classes.cardText}>
              {formFields?.birthDate?.value} - {formFields?.birthTime?.value} -{" "}
              {formFields?.birthPlace?.value}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const getComponentBasedOnValue = (
    tabValue: string,
    subTabValue: string,
    selectedLegend?: string
  ) => {
    if (tabValue === strings.ANALYSIS_TAB) {
      if (subTabValue === strings.DATA) {
        return <AnalysisCards profileId={id!} setIsLoading={setIsLoading} />;
      } else {
        if (selectedLegend === strings.PLANET) {
          return <AnalysisLegends />;
        } else {
          return <RaysLegends />;
        }
      }
    }
    if (tabValue === strings.MINERALS_TAB) {
      if (subTabValue === strings.DATA) {
        return <MineralsCards profileId={id!} setIsLoading={setIsLoading} />;
      } else {
        return <MineralsLegends />;
      }
    }
    if (tabValue === strings.MAHADASHA_TAB) {
      if (subTabValue === strings.DATA) {
        return <Mahadasha profileId={id!} setIsLoading={setIsLoading} />;
      } else {
        return <MahadashaDetails />;
      }
    }
    if (tabValue === strings.PLANET_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <Planets
            profileId={id!}
            house={selectedHouse}
            setIsLoading={setIsLoading}
          />
        );
      }
      // else {
      //   return <MahadashaDetails />;
      // }
    }
  };

  const getMainMenu = () => {
    return (
      <Stack
        direction={{ md: "column", xs: "row" }}
        pt={{ md: 5, xs: 0 }}
        spacing={{ md: 0, xs: 1 }}
        justifyContent={"space-between"}
        sx={
          isTablet
            ? {
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                overflowX: "auto",
                display: "-webkit-box",
              }
            : {}
        }
      >
        {mainTabConfig.map((tab: any, index: number) => {
          return (
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={classes.tabWrapper}
              justifyContent={
                selectedTab === tab.tabName ? "space-between" : "start"
              }
            >
              <Stack direction={"row"} spacing={1}>
                {!isTablet && (
                  <img
                    src={
                      selectedTab === tab.tabName ? tab.activeIcon : tab.icon
                    }
                    alt="Module Not Found..."
                  />
                )}

                <Typography
                  sx={
                    selectedTab === tab.tabName
                      ? classes.gradientTypo
                      : classes.headerText
                  }
                  onClick={() => handleTabChange(tab.tabName)}
                >
                  {t(`${tab.header}`)}
                </Typography>
                {isTablet && index !== mainTabConfig.length - 1 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      border: "1px solid #ffffff59",
                      height: "auto",
                      margin: "0 8px",
                    }}
                  />
                )}
              </Stack>
              {!isTablet && selectedTab === tab.tabName && (
                <Box>
                  <img src={selectedTabIcon} alt="Module Not Found..." />
                </Box>
              )}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const getSubMenu = () => {
    return (
      <>
        <Stack
          direction={"row"}
          spacing={4}
          pl={{ sm: 5, xs: 0 }}
          pb={1}
          pt={{ md: 0, xs: 1 }}
          justifyContent={{ md: "start", xs: "center" }}
          sx={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}
        >
          <Typography
            sx={
              selectedSubTab === strings.DATA
                ? { ...classes.selectedCardLabel, cursor: "pointer" }
                : { ...classes.cardLabel, cursor: "pointer" }
            }
            onClick={() => setSelectedSubTab("Data")}
          >
            {t("data")}
          </Typography>

          {selectedTab === strings.ANALYSIS_TAB ? (
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ p: 0 }}
            >
              <Typography
                sx={
                  selectedSubTab === strings.LEGENDS
                    ? {
                        ...classes.selectedCardLabel,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }
                    : {
                        ...classes.cardLabel,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }
                }
              >
                {t("legendsHeader")}
                <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
              </Typography>
            </IconButton>
          ) : selectedTab === strings.PLANET_TAB ? (
            <CustomSelect
              menuItems={houseMenu}
              onChange={handleHouseChange}
              value={selectedHouse}
              name={"house"}
              id={"house"}
              customClasses={classes.darkTextField}
            />
          ) : (
            <Typography
              className="Test3"
              sx={
                selectedSubTab === strings.LEGENDS
                  ? { ...classes.selectedCardLabel, cursor: "pointer" }
                  : { ...classes.cardLabel, cursor: "pointer" }
              }
              onClick={() => setSelectedSubTab("Legends")}
            >
              {t("legendsHeader")}
            </Typography>
          )}
        </Stack>
        <Box sx={classes.scrollStyle}>
          {getComponentBasedOnValue(
            selectedTab,
            selectedSubTab,
            selectedLegend
          )}
        </Box>
      </>
    );
  };

  const getComponents = () => {
    return (
      <Grid container>
        <Grid
          item
          xl={2}
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={
            !isTablet
              ? { borderRight: "1px solid rgba(255, 255, 255, 0.2)" }
              : {}
          }
        >
          {getMainMenu()}
        </Grid>
        <Grid item xl={10} lg={9} md={9} sm={12} xs={12}>
          {getSubMenu()}
        </Grid>
      </Grid>
    );
  };

  const getData = () => {
    return (
      <Box
        sx={
          isTablet ? { ...classes.mainContainer, mt: 1 } : classes.mainContainer
        }
      >
        <Box sx={classes.mainBox}>
          <Box sx={classes.subBox}>{getComponents()}</Box>
        </Box>
      </Box>
    );
  };

  const getAnalysis = () => {
    return (
      <>
        {getProfileData()}
        {getData()}
        {getMenu()}
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getAnalysis();
};

export default Analysis;
