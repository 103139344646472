import { Box, Container, Stack, Typography } from "@mui/material";
import privacyPolicyStyles from "./PrivacyPolicy.styles";
import AppHeader from "../Shared/AppHeader/AppHeader";
import urls from "../../global/constants/UrlConstants";
import history from "../../utils/history";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppFooter from "../Shared/AppFooter/AppFooter";

const PrivacyPolicy = () => {
  const classes = privacyPolicyStyles;
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getHeaderContent = () => {
    return (
      <>
        <Typography sx={classes.italicTypo} pb={2}>
          {t("privacyPolicy")}
        </Typography>
        <Typography sx={classes.normalTypo}>
          {t("privacyPolicyHeader1")}
        </Typography>
        <Typography sx={classes.normalTypo} pb={2}>
          {t("privacyPolicyHeader2")}
        </Typography>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("contents")}:
        </Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint1")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint2")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint3")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint4")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint5")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint6")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("contentPoint7")}</Typography>
        <Typography sx={classes.pointsTypo} pb={3}>
          {t("contentPoint8")}
        </Typography>
      </>
    );
  };

  const getFirstPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>1.1</Typography>
          {t("point1.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>1.2</Typography>
          {t("point1.2.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          {t("point1.2.2")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          {t("point1.2.3")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          {t("point1.2.4")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          {t("point1.2.5")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>1.3</Typography>
          <Box sx={classes.normalTypo} display="inline-block">
            {t("point1.3.1")}
            <Typography
              display="inline-block"
              sx={classes.linkTypo}
              component="a"
              href={t("point1.3.2")}
              target="_blank"
              rel="noopener noreferrer"
              pl={0.5}
            >
              {t("point1.3.2")}
            </Typography>
          </Box>
        </Typography>

        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>1.4</Typography>
          {t("point1.4.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point1.4.2")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={1}>
          {t("point1.4.3")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>1.5</Typography>
          {t("point1.5.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point1.5.2")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point1.5.3")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point1.5.4")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point1.5.5")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={1}>
          {t("point1.5.6")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>1.6</Typography>
          {t("point1.6")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>1.7</Typography>
          {t("point1.7")}
        </Typography>
      </>
    );
  };

  const getSecondPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header2")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>2.1</Typography>
          {t("point2.1.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point2.1.2")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point2.1.3")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point2.1.4")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={1}>
          {t("point2.1.5")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>2.2</Typography>
          {t("point2.2")}
        </Typography>
      </>
    );
  };

  const getThirdPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header3")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>3.1</Typography>
          {t("point3.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>3.2</Typography>
          {t("point3.2")}
        </Typography>
      </>
    );
  };

  const getForthPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header4")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>4.1</Typography>
          {t("point4.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>4.2</Typography>
          {t("point4.2")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>4.3</Typography>
          {t("point4.3.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point4.3.2")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point4.3.3")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point4.3.4")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point4.3.5")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={3}>
          {t("point4.3.6")}
        </Typography>
      </>
    );
  };

  const getFifthPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header5")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>5.1</Typography>
          {t("point5.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>5.2</Typography>
          {t("point5.2")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>5.3</Typography>
          {t("point5.3")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>5.4</Typography>
          {t("point5.4")}
        </Typography>
      </>
    );
  };

  const getSixthPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header6")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>6.1</Typography>
          {t("point6.1")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>6.2</Typography>
          {t("point6.2")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>6.3</Typography>
          {t("point6.3")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>6.4</Typography>
          {t("point6.4")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>6.5</Typography>
          {t("point6.5")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>6.6</Typography>
          {t("point6.6")}
        </Typography>
      </>
    );
  };

  const getSeventhPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header7")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.1</Typography>
          <Box sx={classes.normalTypo} display="inline-block">
            {t("point7.1.1")}
            <Typography
              display="inline-block"
              sx={classes.linkTypo}
              component="a"
              href={t("point7.1.2")}
              target="_blank"
              rel="noopener noreferrer"
              pl={0.5}
            >
              {t("point7.1.2")}
            </Typography>
          </Box>
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>7.2</Typography>
          {t("point7.2.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.2.2")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.2.3")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.2.4")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.2.5")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.2.6")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={1}>
          {t("point7.2.7")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.3</Typography>
          {t("point7.3")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.4</Typography>
          {t("point7.4")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo}>
          <Typography sx={classes.customDecimal}>7.5</Typography>
          {t("point7.5.1")}
        </Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.5.2")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.5.3")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.5.4")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.5.5")}</Typography>
        <Typography sx={classes.innerPointsTypo}>{t("point7.5.6")}</Typography>
        <Typography sx={classes.innerPointsTypo} pb={1}>
          {t("point7.5.7")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.6</Typography>
          {t("point7.6")}
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.7</Typography>
          <Box sx={classes.normalTypo} display="inline-block">
            {t("point7.7.1")}
            <Typography
              display="inline-block"
              sx={classes.linkTypo}
              component="a"
              href={t("point7.7.2")}
              target="_blank"
              rel="noopener noreferrer"
              pl={0.5}
            >
              {t("point7.7.2")}
            </Typography>
          </Box>
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={1}>
          <Typography sx={classes.customDecimal}>7.8</Typography>
          <Box sx={classes.normalTypo} display="inline-block">
            {t("point7.8.1")}
            <Typography
              display="inline-block"
              sx={classes.linkTypo}
              component="a"
              href={t("point7.8.2")}
              target="_blank"
              rel="noopener noreferrer"
              pl={0.5}
            >
              {t("point7.8.2")}
            </Typography>
          </Box>
        </Typography>

        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>7.9</Typography>
          <Box sx={classes.normalTypo} display="inline-block">
            {t("point7.9.1")}
            <Typography
              display="inline-block"
              sx={classes.linkTypo}
              component="a"
              href={t("point7.9.2")}
              target="_blank"
              rel="noopener noreferrer"
              pl={0.5}
            >
              {t("point7.9.2")}
            </Typography>
          </Box>
        </Typography>
      </>
    );
  };

  const getEighthPoint = () => {
    return (
      <>
        <Typography sx={classes.headingTypo} pb={1}>
          {t("header8")}
        </Typography>
        <Typography sx={classes.decimalPointsTypo} pb={3}>
          <Typography sx={classes.customDecimal}>8.1</Typography>
          {t("point8.1")}
        </Typography>
      </>
    );
  };

  const getEndContent = () => {
    return (
      <>
        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("privacyPolicyFooter")}
        </Typography>
        <Typography sx={classes.normalTypo} pb={3}>
          {t("privacyPolicyContent")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("whatWeCollect")}
        </Typography>
        <Typography sx={classes.normalTypo}>
          {t("whatWeCollectPoint1")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("whatWeCollectPoint2")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("whatWeCollectPoint3")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("whatWeCollectPoint4")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("whatWeCollectPoint5")}
        </Typography>
        <Typography sx={classes.pointsTypo} pb={3}>
          {t("whatWeCollectPoint6")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("whatWeDo")}
        </Typography>
        <Typography sx={classes.normalTypo}>{t("whatWeDoPoint1")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("whatWeDoPoint2")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("whatWeDoPoint3")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("whatWeDoPoint4")}</Typography>
        <Typography sx={classes.pointsTypo} pb={3}>
          {t("whatWeDoPoint5")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("security")}
        </Typography>
        <Typography sx={classes.normalTypo} pb={3}>
          {t("securityPoint1")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("cookiesUse")}
        </Typography>
        <Typography sx={classes.pointsTypo}>{t("cookiesUsePoint1")}</Typography>
        <Typography sx={classes.pointsTypo}>{t("cookiesUsePoint2")}</Typography>
        <Typography sx={classes.pointsTypo} pb={3}>
          {t("cookiesUsePoint3")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("linksToOther")}
        </Typography>
        <Typography sx={classes.normalTypo} pb={3}>
          {t("linksToOtherPoint1")}
        </Typography>

        <Typography sx={classes.subHeadingTypo} pb={1}>
          {t("controlOnInfo")}
        </Typography>
        <Typography sx={classes.normalTypo}>
          {t("controlOnInfoPoint1")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("controlOnInfoPoint2")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("controlOnInfoPoint3")}
        </Typography>
        <Typography sx={classes.pointsTypo}>
          {t("controlOnInfoPoint4")}
        </Typography>
        <Typography sx={classes.pointsTypo} pb={3}>
          {t("controlOnInfoPoint5")}
        </Typography>

        <Typography sx={classes.normalTypo}>{t("privacyPolicyEnd")}</Typography>
      </>
    );
  };

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="xl">
                <Stack direction={"column"} alignContent={"center"}>
                  {getHeaderContent()}
                  {getFirstPoint()}
                  {getSecondPoint()}
                  {getThirdPoint()}
                  {getForthPoint()}
                  {getFifthPoint()}
                  {getSixthPoint()}
                  {getSeventhPoint()}
                  {getEighthPoint()}
                  {getEndContent()}
                </Stack>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getPrivacyPolicy = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box sx={{ background: "#0000004f" }}>{getContent()}</Box>
        <AppFooter />
      </>
    );
  };
  return getPrivacyPolicy();
};

export default PrivacyPolicy;
