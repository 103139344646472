import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import customButtonStyles from "./CustomButton.styles";
import strings from "../../constants/StringConstants";

interface CustomProps {
  label?: string | JSX.Element;
  onClick: Function;
  loading?: boolean;
  customClasses?: any;
  icon?: JSX.Element;
  disabled?: boolean;
  type?: string;
}

const CustomButton = (props: CustomProps) => {
  const classes = customButtonStyles;

  const btnStyle =
    props.type === strings.PRIMARY
      ? classes.primaryBtnStyle
      : classes.secondaryBtnStyle;

  const processing = props.loading ? props.loading : false;
  const disabled = props.disabled ? props.disabled : false;

  return (
    <Button
      sx={props.customClasses ? [btnStyle, props.customClasses] : btnStyle}
      onClick={(event: any) => props.onClick(event)}
      disabled={processing || disabled}
    >
      <Box sx={classes.btnBox}>
        {processing ? (
          <CircularProgress sx={classes.loadingStyle} />
        ) : (
          <>
            {props.icon}
            {props.label}
          </>
        )}
      </Box>
    </Button>
  );
};

export default CustomButton;
