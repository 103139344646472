import urls from "../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../utils/service";

export const getMoonPhases = async (language: string) => {
  const url = `${urls.getMoonPhases}/${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getNotificationCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.notificationCount, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getNotifications = async (language: string) => {
  const url = `${urls.notificationList}${language}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateNotifications = async (ids: any, language: string) => {
  const url = `${urls.notificationList}${language}`;
  try {
    const callParams = await getCallParams("PUT", { ids });
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteNotifications = async (ids: any, language: string) => {
  const url = `${urls.notificationList}${language}`;
  try {
    const callParams = await getCallParams("DELETE", { ids });
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const logout = async (token: string) => {
  try {
    const callParams = await getCallParams("DELETE", { token });
    const response = await makeCall(urls.logout, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
