import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import pricingStyles from "../Pricing.styles";
import { useTranslation } from "react-i18next";
import failImage from "../../../assets/images/failImage.png";
import { CustomButton } from "../../../global/components";
import strings from "../../../global/constants/StringConstants";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import { theme } from "../../../utils/styles";
import { useState } from "react";
import AppHeader from "../../Shared/AppHeader/AppHeader";
import AppFooter from "../../Shared/AppFooter/AppFooter";

const Fail = () => {
  const classes = pricingStyles;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getHeader = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2}>
        <Typography sx={classes.italicTypo}>{t("failPayment")}</Typography>
        <Divider sx={classes.dividerStyle} />
      </Stack>
    );
  };

  const getContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} pt={6}>
        {isMobile ? (
          <img src={failImage} height={"150px"} />
        ) : (
          <img src={failImage} />
        )}
        <Typography sx={classes.normalTypo} pt={1}>
          {t("failPaymentMsg1")}
        </Typography>
        <Typography sx={classes.boldTypo} pb={6}>
          {t("failPaymentMsg2")}
        </Typography>
        {/* <CustomButton
          label={t("payment")}
          type={strings.SECONDARY}
          customClasses={{ maxWidth: "fit-content" }}
          onClick={() => history.push(urls.pricingViewPath)}
        /> */}
        <Typography sx={classes.normalTypo} pt={4}>
          {t("closeWindow")}
        </Typography>
        {/* <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            textAlign={{ sm: "right", xs: "center" }}
            px={1}
          >
            <CustomButton
              label={t("payment")}
              type={strings.SECONDARY}
              customClasses={{ maxWidth: "fit-content" }}
              onClick={() => history.push(urls.pricingViewPath)}
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={12}
            textAlign={{ sm: "left", xs: "center" }}
            px={1}
          >
            <CustomButton
              label={t("backToHome")}
              type={strings.PRIMARY}
              customClasses={{ maxWidth: "fit-content" }}
              onClick={() => history.push(urls.dashboardViewPath)}
            />
          </Grid>
        </Grid> */}
      </Stack>
    );
  };

  const getPage = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="lg">
                {getHeader()}
                {getContent()}
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getFailPage = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box sx={{ background: "#0000004f" }}>{getPage()}</Box>
        <AppFooter />
        {/* <Box py={1.5}>{getFooter()}</Box> */}
      </>
    );
  };

  return getFailPage();
};

export default Fail;
