import { StringConstants } from "./StringConstants";

class NotificationConstants extends StringConstants {
  GENERIC_ERROR = "Something went wrong! Please try again.";
  VALIDDETAILS = "Please fill valid details.";
  LOGGEDOUT = "You have been logged out of the system. Please log in again.";
  LOGGEDIN = "Login Successful.";
  LOGIN_ERROR = "Please enter valid email id and password";
}

let notifiers = new NotificationConstants();
export default notifiers;
