import {
  boldFont,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  regularFont,
  theme,
} from "../../../../utils/styles";

const addCardStyles = {
  modalHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(10),
    color: "#222222",
    textAlign: "center",
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  lightFont: {
    fontSize: getRelativeFontSize(2),
    ...regularFont,
    color: "#666666",
  },
  cancelBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  cancelBtnBox: {
    background: "#FFFFFF",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  logoutBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color: "#FFFFFF",
      background: "#FFFFFF",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(5),
    },
  },

  // card element
  label: { display: "flex", height: "20px" },
  labelTypo: {
    color: "#000000",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
  },
  cardElementStyle: {
    borderRadius: "8px",
    border: "1px solid #000000",
    background: "rgba(255, 255, 255)",
    backdropFilter: "blur(5.5px)",
    p: 2,
  },
} as const;

export default addCardStyles;
