import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { AppPlans } from "../../../models/interfaces";
import pricingStyles from "../Pricing.styles";
import he from "he";
import { useEffect, useState } from "react";
import { getAppPlans } from "../PricingService";
import { handleErrorMessage } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import standardPlanIcon from "../../../assets/icons/standardPlanIcon.svg";
import premiumPlanIcon from "../../../assets/icons/premiumPlanIcon.svg";
import enterprisePlanIcon from "../../../assets/icons/enterprisePlanIcon.svg";
import recommendedPlan from "../../../assets/icons/recommendedPlan.svg";
import { centerItemFlex } from "../../../utils/styles";
import { useTranslation } from "react-i18next";

interface CustomProps {
  setIsLoading: Function;
  change?: boolean;
}

const Plans = (props: CustomProps) => {
  const classes = pricingStyles;
  const { t, i18n } = useTranslation();
  const [plans, setPlans] = useState<AppPlans[]>([]);
  const [keyFeatures, setKeyFeatures] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<AppPlans>();

  const planImage: any = {
    Basic: standardPlanIcon,
    Premium: premiumPlanIcon,
    Enterprise: enterprisePlanIcon,
  };

  useEffect(() => {
    getPlansList();
  }, [i18n.language]);

  const getPlansList = async () => {
    try {
      props.setIsLoading(true);
      const language = i18n.language.toLowerCase();
      const response = await getAppPlans(language);
      setPlans(response.plans);
      setKeyFeatures(response.keyFeatures);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getCards = () => {
    return (
      <Grid container justifyContent={"end"} spacing={2}>
        {plans?.map((plan: AppPlans, index: number) => {
          return (
            <Grid item xl={6} lg={8} md={10} sm={12} xs={12}>
              {plan.isRecommended && (
                <>
                  <Typography sx={classes.recommendsText}>
                    ★ {t("recommends")}
                  </Typography>
                  <Box sx={classes.recommendedPlan}>
                    <img
                      src={recommendedPlan}
                      alt="Module Not Found..."
                      style={{ position: "relative", top: "2px" }}
                      height={"40px"}
                    />
                  </Box>
                </>
              )}

              <Box
                sx={
                  plan === selectedPlan
                    ? { ...classes.priceBox, border: "1px solid #8876F0" }
                    : classes.priceBox
                }
                mt={!plan.isRecommended ? 4 : 0}
              >
                <Stack direction={"column"} alignItems={"center"}>
                  <img
                    src={planImage[plan.name ?? "Basic"]}
                    alt="Module Not Found..."
                    height={"80px"}
                  />
                  <Typography sx={classes.planText} py={{ sm: 2, xs: 1 }}>
                    {plan.name}
                  </Typography>
                  <Divider
                    sx={{
                      border: "1px solid rgba(255, 255, 255, 0.05)",
                      width: "100%",
                    }}
                  />
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    py={{ sm: 2, xs: 1 }}
                  >
                    <Typography sx={classes.priceText}>
                      {he.decode(plan?.price!)}
                    </Typography>
                    <Typography pl={1} sx={classes.durationText}>
                      /{plan.duration}
                    </Typography>
                  </Box>
                  <Typography sx={classes.planDescText} pb={{ sm: 4, xs: 2 }}>
                    {plan.details}
                  </Typography>

                  <Button
                    sx={
                      plan === selectedPlan
                        ? {
                            ...classes.planBtn,
                            ...classes.selectedPlanBtn,
                          }
                        : classes.planBtn
                    }
                    onClick={() => {
                      setSelectedPlan(plan!);
                    }}
                  >
                    {t("selectPlan")}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getSubscriptionBtn = () => {
    return (
      <Box
        marginTop={"50px"}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Button
          sx={classes.subscribeBtn}
          onClick={() => {
            history.push({
              pathname: urls.paymentViewPath,
              state: { selectedPlan, change: props.change },
            });
          }}
          disabled={!selectedPlan}
        >
          <Typography sx={classes.subscribeBtnTypo}>
            {t("subscribeHeader")}
          </Typography>
        </Button>
      </Box>
    );
  };

  const getPlanDescription = () => {
    return (
      <Stack direction={"column"}>
        <Typography sx={classes.priceText} pb={3}>
          {t("keyFeatures")}:
        </Typography>
        {keyFeatures?.map((features: string) => {
          return (
            <Typography pb={0.5} ml={2.5} sx={classes.featuresText}>
              {features}
            </Typography>
          );
        })}
      </Stack>
    );
  };

  const getHeader = () => {
    return (
      <Typography sx={classes.italicTypo}>{t("subscribeHeader")}</Typography>
    );
  };

  const getPlans = () => {
    return (
      <>
        {getHeader()}
        <Grid container pt={3} sx={{ ...centerItemFlex }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} px={{ sm: 4, xs: 0 }}>
            {getCards()}
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            px={{ sm: 4, xs: 0 }}
            pt={{ sm: 0, xs: 2 }}
          >
            {getPlanDescription()}
          </Grid>
        </Grid>
        {getSubscriptionBtn()}
      </>
    );
  };
  return getPlans();
};

export default Plans;
