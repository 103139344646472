import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  pureWhiteColor,
  regularFont,
  theme,
} from "../../../utils/styles";

const appHeaderStyles = {
  appHeaderTitle: {
    ...mediumFont,
  },
  menuMobile: {
    width: "100vw",
  },
  headerText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    cursor: "pointer",
    "&:hover": {
      color: "#f15e22",
      transition: "all 0.5s ease",
      textShadow: "0 0 15px #f15e22",
    },
    [theme.breakpoints.down(1487)]: {
      fontSize: getRelativeFontSize(2),
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(6),
    },
  },
  selectedHeaderText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(6),
    color: "#f15e22",
    cursor: "pointer",
  },
  addressText: {
    ...regularFont,
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#FFFFFF",
    [theme.breakpoints.down(1350)]: {
      fontSize: "13px",
    },
  },
  iconList: {
    position: "relative",
    height: "50px",
    width: "50px",
    display: "-webkit-box",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "5px solid #FFFFFF",
    borderRadius: "50%",
    overflow: "hidden",
    transition: "all 500ms ease",
    zIndex: "1",
    cursor: "pointer",
    marginRight: "5px",
    "&:after": {
      backgroundColor: "#f15e22",
      borderRadius: "30px",
      content: `''`,
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      transform: "translate(0, -100%) ",
      transformOrigin: "bottom",
      transition: "all .5s ease-in-out",
      willChange: "transform",
      zIndex: -1,
    },
    "&:hover": {
      "& .profileText": {
        transition: "all .5s ease-in-out",
        color: "#FFFFFF",
      },
      "&:after": {
        transform: "translate(0, 0)",
      },
    },
    [theme.breakpoints.down(1350)]: {
      height: "40px",
      width: "40px",
    },
  },
  profileText: {
    ...regularFont,
    fontWeight: "700",
    fontSize: "25px",
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      transition: "all .5s ease-in-out",
      color: "#f4875a",
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: "20px",
    },
  },
  icon: {
    fill: "#FFFFFF",
  },
  textField: {
    maxWidth: "160px",
    width: "100%",
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
    },
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 8px",
      },

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  darkTextField: {
    maxWidth: "180px",
    width: "100%",
    borderRadius: "24px",
    border: "1px solid rgba(0, 0, 0, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    boxShadow: "none",

    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 8px",
      },

      "&::placeholder": {
        ...mediumFont,
        color: "#000000A6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#000000",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  languageTypo: {
    fontSize: getRelativeFontSize(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "jejuhallasan-regular",
    fontSize: "77px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  descText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(7),
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  moonBox: {
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    pr: 2,
    pl: 1,
  },
  moonDarkBox: {
    borderRadius: "24px",
    border: "1px solid rgba(0, 0, 0, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    pr: 2,
    pl: 1,
  },
  moonHeader: {
    ...regularFont,
    color: "#FFFFFF",
    // whiteSpace: "nowrap",
    fontSize: getRelativeFontSize(1),
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  moonDesc: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(1),
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  menuItemText: {
    ...mediumFont,
    color: "#424242",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  backText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  dividerStyle: {
    border: "1px solid #FFFFFF",
    width: "100%",
    height: "0px",
    marginTop: "0px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "85%",
    // },
  },
  modalHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(10),
    color: "#222222",
    textAlign: "center",
  },
  lightFont: {
    fontSize: getRelativeFontSize(2),
    ...regularFont,
    color: "#A7A7A7",
    textAlign: "center",
  },
  cancelBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(3),
    },
  },
  cancelBtnBox: {
    background: "#FFFFFF",
    padding: "12px 0px",
    borderRadius: "10px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  logoutBtn: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "10px",
    boxSizing: "border-box",
    maxWidth: "200px",
    display: "block",
    height: "56px",
    letterSpacing: "1px",
    margin: "0 auto",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      color: "#FFFFFF",
      background: "#FFFFFF",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: getRelativeFontSize(5),
    },
  },
  menuWrapper: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    px: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  dialogContent: {
    margin: "0 5%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0%",
    },
  },
  vedicMoonBox: {
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    pr: 2,
    pl: 1,
    minWidth: "auto",
  },
  notificationBox: {
    ...centerItemFlex,
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    minWidth: "auto",
    padding: "20px",
    cursor: "pointer",
  },
  notificationBadge: {
    "& .MuiBadge-badge": {
      color: pureWhiteColor,
      backgroundColor: "#8A31FF",
    },
  },
  notificationHeaderBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "70px",
  },
  notificationHeader: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: pureWhiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(5),
    },
  },
  notificationCount: {
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    color: "#ffffff",
    background: "#8A31FF",
    padding: "10px 15px",
    borderRadius: "50%",
  },
  notificationMsgWrapper: {
    pr: 5,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  notificationText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: pureWhiteColor,
    paddingLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  notificationDateBox: {
    background: "#171442",
    py: 2,
  },
  notificationDateText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    color: pureWhiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  notificationTimeText: {
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    color: "#DDDDDD",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-2),
    },
  },
  clearAllText: {
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    color: "#DDDDDD",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-2),
    },
  },
  noNotificationHead: {
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    color: pureWhiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  noNotificationContent: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: "#DDDDDD",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
} as const;

export default appHeaderStyles;
