import { createSubscription, updatePlan } from "../PricingService";
import {
  handleErrorMessage,
  openSuccessNotification,
} from "../../../helpers/methods";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import pricingStyles from "../Pricing.styles";
import { AppPlans } from "../../../models/interfaces";
import he from "he";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import standardPlanIcon from "../../../assets/icons/standardPlanIcon.svg";
import premiumPlanIcon from "../../../assets/icons/premiumPlanIcon.svg";
import enterprisePlanIcon from "../../../assets/icons/enterprisePlanIcon.svg";
import { useAppDispatch } from "../../../utils/hooks";
import { subscriptionAction } from "../../../redux/authSlice";
import CardComponent from "../../Shared/CardComponent/CardComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
  selectedPlan?: AppPlans;
  change?: boolean;
  setIsLoading: Function;
}

const Payment = (props: CustomProps) => {
  const classes = pricingStyles;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [paymentMethodId, setPaymentMethodId] = useState<string>("");

  const planImage: any = {
    Basic: standardPlanIcon,
    Premium: premiumPlanIcon,
    Enterprise: enterprisePlanIcon,
  };

  function handleRedirect(url: string) {
    window.open(url, "_blank");
    history.push(urls.subscriptionViewPath);
  }

  function handleNotRedirect(message: string, id: string) {
    openSuccessNotification(message);
    dispatch(subscriptionAction({ isSubscribed: true }));
    history.push(urls.dashboardViewPath);
    window.open(`${urls.successViewPath}?id=${id}`);
  }

  const handleSubmit = async () => {
    try {
      props.setIsLoading(true);
      const response = props.change
        ? await updatePlan(props.selectedPlan?.priceId!, paymentMethodId)
        : await createSubscription(
            props.selectedPlan?.priceId!,
            paymentMethodId
          );
      response.redirect
        ? handleRedirect(response.url)
        : handleNotRedirect(response.message, response.userId);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      window.open(urls.failViewPath);
      handleErrorMessage(error);
    }
  };

  const getSubscriptionBtn = () => {
    return (
      <Box mt={3} width={"100%"} display={"flex"} justifyContent={"center"}>
        <Button sx={classes.subscribeBtn} onClick={handleSubmit}>
          <Typography sx={classes.subscribeBtnTypo}>{t("submit")}</Typography>
        </Button>
      </Box>
    );
  };

  const getHeader = () => {
    return (
      <Typography sx={classes.italicTypo} pt={1}>
        {t("paymentInformation")}
      </Typography>
    );
  };

  const getSelectedPlan = () => {
    return (
      <Grid item xl={4} lg={4} md={6} sm={12} xs={12} px={{ sm: 4, xs: 0 }}>
        <Box sx={classes.priceBox}>
          <Stack direction={"column"} alignItems={"center"}>
            <img
              src={planImage[props.selectedPlan?.name ?? "Basic"]}
              alt="Module Not Found..."
              height={"80px"}
            />
            <Typography sx={classes.planText} py={2}>
              {props.selectedPlan?.name}
            </Typography>
            <Divider
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.05)",
                width: "100%",
              }}
            />
            <Box display={"flex"} alignItems={"center"} py={2}>
              <Typography sx={classes.priceText}>
                {he.decode(props.selectedPlan?.price!)}
              </Typography>
              <Typography pl={1} sx={classes.durationText}>
                /{props.selectedPlan?.duration}
              </Typography>
            </Box>

            <Typography sx={classes.planDescText} pb={4}>
              {props.selectedPlan?.details}
            </Typography>

            <Button
              sx={classes.planBtn}
              onClick={() => {
                history.push(urls.pricingViewPath);
              }}
            >
              {t("changePlan")}
            </Button>
          </Stack>
        </Box>
      </Grid>
    );
  };

  const getCardElement = () => {
    return (
      <Grid
        item
        xl={8}
        lg={8}
        md={6}
        sm={12}
        xs={12}
        px={{ sm: 4, xs: 0 }}
        pt={{ sm: 0, xs: 4 }}
        display={"block"}
        alignSelf={"center"}
      >
        <CardComponent
          setIsLoading={props.setIsLoading}
          selection={true}
          setPaymentMethodId={setPaymentMethodId}
          priceId={props.selectedPlan?.priceId!}
        />
        {paymentMethodId && getSubscriptionBtn()}
      </Grid>
    );
  };

  const getCheckoutForm = () => {
    return (
      <Container maxWidth="lg">
        {getHeader()}
        <Grid container pt={4} alignItems={"center"}>
          {getSelectedPlan()}
          {getCardElement()}
        </Grid>
      </Container>
    );
  };

  return getCheckoutForm();
};

export default Payment;
