import { CustomDialog } from "../../../../global/components";
import addCardStyles from "./AddCardDialog.styles";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import urls from "../../../../global/constants/UrlConstants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardElementComponent from "./CardElementComponent";
import { useTranslation } from "react-i18next";

interface CustomProps {
  setIsLoading: Function;
  handleClose: Function;
  setPaymentMethods: Function;
  open: boolean;
}

const AddCardDialog = (props: CustomProps) => {
  const classes = addCardStyles;
  const { t } = useTranslation();
  const promise: any = loadStripe(urls.STRIPE_PUBLIC_KEY);

  const cardElementRef: any = useRef();

  const handlePayButtonClick = () => {
    cardElementRef.current.handleSubmit();
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.modalHeader} px={2}>
          {t("addPaymentMethodHeader")}
        </Typography>

        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} pb={5} spacing={2} sx={classes.dialogContent}>
        <Typography sx={classes.lightFont} pt={2}>
          {t("addPaymentMethodContent")}
        </Typography>
        <Elements stripe={promise}>
          <CardElementComponent
            ref={cardElementRef}
            setIsLoading={props.setIsLoading}
            handleClose={props.handleClose}
            setPaymentMethods={props.setPaymentMethods}
          />
        </Elements>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={2}
          alignSelf={"center"}
          pt={3}
        >
          <Button onClick={() => props.handleClose()} sx={classes.cancelBtn}>
            <Box sx={classes.cancelBtnBox}>
              <Typography className="btnText"> {t("cancel")}</Typography>
            </Box>
          </Button>
          <Button onClick={handlePayButtonClick} sx={classes.logoutBtn}>
            <Typography className="btnText"> {t("add")}</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };
  const getAddDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={props.open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={() => props.handleClose()}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };
  return getAddDialog();
};

export default AddCardDialog;
