import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import { registerUser, resendOtp, verifyOtp } from "./AuthService";
import urls from "../../global/constants/UrlConstants";
import loginStyles from "./Auth.styles";
import history from "../../utils/history";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "../../global/components";
import {
  registerFormField,
  registrationValidation,
} from "./AuthTypesAndValidation";
import { tempTokenAction } from "../../redux/authSlice";
import strings from "../../global/constants/StringConstants";
import { useAppDispatch } from "../../utils/hooks";
import OtpInput from "react-otp-input";
import { CustomContactNumberInput } from "../../global/components/CustomContactNumberInput/CustomContactNumberInput";
import { useTranslation } from "react-i18next";

const Register = () => {
  const classes = loginStyles;
  const emailRegex = strings.regex;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(registerFormField);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [seconds, setSeconds] = useState(59);
  const [visible, setVisible] = useState(true);
  const [timerId, setTimerId] = useState<any>(null);
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        location: {
          ...formFields.location,
          value: address.location,
        },
      });
    !formFields.location.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  const startTimer = () => {
    if (timerId) {
      clearInterval(timerId);
    }
    setSeconds(59);
    setVisible(true);
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          setVisible(false);
          clearInterval(intervalId);
        }
        return prevSeconds - 1;
      });
    }, 1000);
    setTimerId(intervalId);
  };

  const handleRegister = async () => {
    try {
      if (handleValidation()) {
        const userData = {
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          email: formFields.email.value,
          location: address.location,
          latitude: address.latitude,
          longitude: address.longitude,
          contact: formFields.contact.value,
        };
        setIsLoading(true);
        const response = await registerUser(userData);
        setToken(response.accessToken);
        dispatch(
          tempTokenAction({
            tempToken: response.accessToken,
          })
        );
        openSuccessNotification(response.message);
        setOpen(true);
        startTimer();
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setIsLoading(true);
      const response = await verifyOtp(token, code);
      openSuccessNotification(response.message);
      history.push(urls.setPasswordViewPath);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsLoading(true);
      const response = await resendOtp(token, formFields.email.value);
      openSuccessNotification(response.message);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
    if (event.target.name === "location") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleRegister();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = registrationValidation(formFields, address);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatTime = (time: any) => {
    return time < 10 ? `0${time}` : time;
  };

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  function initialize() {
    const input: any = document.getElementById("location");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  }

  const getSignUpScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={"space-between"}
        >
          <Typography sx={classes.getHeading}> {t("signup")}</Typography>
          <Typography
            sx={classes.getHeading2}
            onClick={() => {
              history.push(urls.loginViewPath);
            }}
          >
            {t("login")}
          </Typography>{" "}
        </Stack>
        <Typography pt={1} sx={classes.welcomeTypo}>
          {t("welcome")}
        </Typography>
        <Box sx={classes.innerGetLoginBox} pt={{ sm: 7, xs: 5 }}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                required
                label={t("firstName")}
                placeHolder={t("firstNamePlaceholder")}
                id="firstName"
                type="firstName"
                name="firstName"
                value={formFields.firstName.value}
                onChange={handleOnChangeInputField}
                onKeyPress={handleKeypress}
                error={
                  !isTruthy(formFields.firstName.value) &&
                  formFields.firstName.error
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                required
                label={t("lastName")}
                placeHolder={t("lastNamePlaceholder")}
                id="lastName"
                type="lastName"
                name="lastName"
                value={formFields.lastName.value}
                onChange={handleOnChangeInputField}
                onKeyPress={handleKeypress}
                error={
                  !isTruthy(formFields.lastName.value) &&
                  formFields.lastName.error
                }
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomInput
                required
                label={t("email")}
                placeHolder={t("emailPlace")}
                id="email"
                type="email"
                name="email"
                value={formFields.email.value}
                onChange={handleOnChangeInputField}
                onKeyPress={handleKeypress}
                error={
                  !isTruthy(formFields.email.value) && formFields.email.error
                }
              />
              {!emailRegex.test(formFields.email.value) &&
                formFields.email.value.length > 0 && (
                  <FormHelperText error sx={classes.errorStyling}>
                    Please enter valid email id
                  </FormHelperText>
                )}
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomInput
                required
                label={t("location")}
                placeHolder={t("locationPlace")}
                id="location"
                type="location"
                name="location"
                value={formFields.location.value}
                onChange={handleOnChangeInputField}
                onKeyPress={handleKeypress}
                error={
                  !isTruthy(
                    formFields.location.value &&
                      address.latitude &&
                      address.longitude
                  ) && formFields.location.error
                }
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomContactNumberInput
                label={t("contact")}
                id="contact_no_modal_field"
                value={formFields.contact.value}
                placeHolder="Enter Your Contact No"
                onChange={(phone: any) => {
                  setFormFields({
                    ...formFields,
                    contact: {
                      value: phone,
                      error: "",
                    },
                  });
                }}
                error={formFields.contact.error}
              />
            </Grid>
          </Grid>

          <Box marginTop={"50px"} width={"100%"}>
            <Box pb={1} sx={{ cursor: "pointer" }}>
              <Typography sx={classes.noteTypo}>{t("optMsg")}</Typography>
            </Box>
            <CustomButton
              label={t("otp")}
              type={strings.SECONDARY}
              loading={isLoading}
              onClick={handleRegister}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.dialogTitle}>
          {t("verificationCode")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #E7E7E7",
            width: "80%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} pb={5}>
        <Typography sx={classes.lightTypo} pb={1}>
          {t("verificationCodeMsg")}
        </Typography>
        <Typography sx={classes.dialogSubTypo} pb={4}>
          &quot;{formFields.email.value}&quot;
        </Typography>
        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={4}
          renderInput={(props: any) => <input {...props} />}
          inputStyle={classes.otpInput}
        />
        {/* <OTPInput
          inputStyle={classes.otpInput}
          length={4}
          onChangeOTP={(otp) => setCode(otp)}
        /> */}
        <Button
          sx={{ ...classes.loginBtn, minWidth: "280px", mt: 2 }}
          disabled={code.length < 4}
          onClick={handleVerifyOtp}
        >
          <Typography sx={{ ...classes.loginBtnTypo, fontSize: "15px" }}>
            Submit
          </Typography>
        </Button>
        {visible ? (
          <Typography sx={classes.dialogSubTypo} pt={4}>
            Resend OTP in {formatTime(0)}:{formatTime(seconds)} seconds
          </Typography>
        ) : (
          <Typography
            sx={{ ...classes.dialogSubTypo, cursor: "pointer" }}
            pt={4}
            onClick={handleResendOtp}
          >
            Resend OTP
          </Typography>
        )}
      </Stack>
    );
  };

  const getOtpDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  return (
    <Box px={1} sx={classes.scrollElement}>
      {getSignUpScreen()}
      {getOtpDialog()}
      {/* <CustomLoader isLoading={isLoading} /> */}
    </Box>
  );
};

export default Register;
