import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  handleErrorMessage,
  openSuccessNotification,
} from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import { Box, Typography } from "@mui/material";
import addCardStyles from "./AddCardDialog.styles";
import { addPaymentMethod } from "./AddCardDialogService";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
  setIsLoading: Function;
  handleClose: Function;
  setPaymentMethods: Function;
}

const cardStyle = {
  style: {
    base: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardElementComponent = forwardRef((props: CustomProps, ref: any) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements: any = useElements();
  const classes = addCardStyles;

  const handleSubmit = async () => {
    try {
      props.setIsLoading(true);
      if (stripe !== null && elements !== null) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
        });
        if (error) {
          handleErrorMessage(error);
          props.setIsLoading(false);
        } else {
          try {
            const response = await addPaymentMethod(paymentMethod.id);
            props.setPaymentMethods(response.paymentMethods);
            openSuccessNotification(response.message);
            props.handleClose();
            props.setIsLoading(false);
          } catch (error: any) {
            props.setIsLoading(false);
            handleErrorMessage(error);
          }
        }
      }
    } catch (error: any) {
      handleErrorMessage(error);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={classes.label} my={2}>
        <Typography sx={classes.labelTypo} className="loginValidation">
          {t("cardDetails")}
        </Typography>
        <Typography sx={classes.star}>*</Typography>
      </Box>
      <Box sx={classes.cardElementStyle}>
        <CardElement options={cardStyle} />
      </Box>
    </form>
  );
});

export default CardElementComponent;
