import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import {
  LightTooltip,
  centerItemFlex,
  pureWhiteColor,
  theme,
} from "../../../utils/styles";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import appHeaderStyles from "./AppHeader.styles";
import noData from "../../../assets/images/noData.svg";
import { handleErrorMessage } from "../../../helpers/methods";
import {
  deleteNotifications,
  getNotificationCount,
  getNotifications,
  updateNotifications,
} from "./AppHeaderServices";
import React from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
  open: boolean;
  setOpen: Function;
  drawerWidth?: any;
}

const NotificationDrawer = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const drawerWidth = props.drawerWidth ? props.drawerWidth : 300;
  const { i18n } = useTranslation();
  const [display, setDisplay] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [lastDate, setLastDate] = useState<string>("");

  useEffect(() => {
    props.open && getNotificationList();
  }, [props.open]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setLastDate(notifications[0].date);
    }
  }, [notifications]);

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  const getNotificationList = async () => {
    try {
      setDisplay(true);
      const language = i18n.language.toLowerCase();
      const [response, count] = await Promise.all([
        getNotifications(language),
        getNotificationCount(),
      ]);
      setNotifications(response);
      setNotificationCount(count.count);
      setDisplay(false);
    } catch (error: any) {
      setDisplay(false);
      handleErrorMessage(error);
    }
  };

  const updateNotificationList = async (notificationId?: number) => {
    try {
      let ids = [];
      if (notificationId) {
        ids = [notificationId];
      } else {
        ids = notifications?.map((notify) => notify.id) || [];
      }
      const language = i18n.language.toLowerCase();
      setDisplay(true);
      const [response, count] = await Promise.all([
        updateNotifications(ids, language),
        getNotificationCount(),
      ]);
      setNotifications(response);
      setNotificationCount(count.count);
      setDisplay(false);
    } catch (error: any) {
      setDisplay(false);
      handleErrorMessage(error);
    }
  };

  const deleteNotificationList = async (notificationId?: number) => {
    try {
      let ids = [];
      if (notificationId) {
        ids = [notificationId];
      } else {
        ids = notifications?.map((notify) => notify.id) || [];
      }
      const language = i18n.language.toLowerCase();
      setDisplay(true);
      const [response, count] = await Promise.all([
        deleteNotifications(ids, language),
        getNotificationCount(),
      ]);
      setNotifications(response);
      setNotificationCount(count.count);
      setDisplay(false);
    } catch (error: any) {
      setDisplay(false);
      handleErrorMessage(error);
    }
  };

  const getNotificationDrawer = () => {
    return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
          "& .MuiDrawer-paper": {
            background: "#05022A",
            width: drawerWidth,
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        }}
        variant="persistent"
        anchor="right"
        open={props.open}
      >
        <Stack
          direction={"row"}
          sx={classes.notificationHeaderBox}
          pt={6}
          px={5}
        >
          <Stack direction={"row"} spacing={2}>
            <Typography sx={classes.notificationHeader}>
              Notifications
            </Typography>
            {/* <Box sx={classes.notificationCount}>{notificationCount}</Box> */}
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Typography
              sx={classes.clearAllText}
              onClick={() => updateNotificationList()}
            >
              Read All
            </Typography>
            <Typography
              sx={classes.clearAllText}
              onClick={() => deleteNotificationList()}
            >
              Clear All
            </Typography>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                border: "1px solid white",
                borderRadius: "50px",
                padding: "2px",
              }}
            >
              <CloseIcon fontSize="small" htmlColor={pureWhiteColor} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction={"column"}>
          {display ? (
            <>
              {[...Array(5)].map((_, index) => (
                <React.Fragment key={index}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "30px", width: "150px", mb: 1 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={60}
                    sx={{ mb: 2 }}
                  />
                </React.Fragment>
              ))}
            </>
          ) : notifications.length > 0 ? (
            notifications?.map((notify: any, index: number) => {
              const showDate =
                index === 0 || notifications[index - 1].date !== notify.date;
              return (
                <React.Fragment key={index}>
                  {showDate && (
                    <Box sx={classes.notificationDateBox} mb={2}>
                      <Typography sx={classes.notificationDateText} px={5}>
                        {notify.date}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={
                      notify.status
                        ? {
                            ...classes.notificationMsgWrapper,
                            cursor: "auto",
                          }
                        : classes.notificationMsgWrapper
                    }
                    mb={2}
                  >
                    <LightTooltip
                      placement="bottom"
                      arrow
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#616161E6",
                          fontSize: "12px",
                          color: "#FFFFFF",
                        },
                      }}
                      title={"Delete Notification"}
                    >
                      <IconButton
                        onClick={() => deleteNotificationList(notify.id)}
                      >
                        <CloseIcon
                          sx={{ fontSize: "14px" }}
                          htmlColor={pureWhiteColor}
                        />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip
                      placement="bottom"
                      arrow
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#616161E6",
                          fontSize: "12px",
                          color: "#FFFFFF",
                        },
                      }}
                      title={notify.status ? "" : "Mark As Read"}
                    >
                      <Typography
                        onClick={() => updateNotificationList(notify.id)}
                        sx={
                          notify.status
                            ? {
                                ...classes.notificationText,
                                color: "#ffffff75",
                              }
                            : classes.notificationText
                        }
                      >
                        {notify.message}
                      </Typography>
                    </LightTooltip>
                    <Typography
                      sx={classes.notificationTimeText}
                      alignSelf={"center"}
                      pr={1}
                      pl={3}
                    >
                      {notify.time}
                    </Typography>
                  </Box>
                </React.Fragment>
              );
            })
          ) : (
            <Box sx={{ textAlign: "center" }} pt={10}>
              <img src={noData} />
              <Typography sx={classes.noNotificationHead} pt={5}>
                No Notifications Yet
              </Typography>
              <Typography sx={classes.noNotificationContent} pt={1}>
                You have no notifications right now. Come back later
              </Typography>
            </Box>
          )}
        </Stack>
      </Drawer>
    );
  };

  return getNotificationDrawer();
};

export default NotificationDrawer;
