import {
  Box,
  Grid,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import analysisStyles from "../../Analysis.styles";
import { theme } from "../../../../utils/styles";
import moonIcon from "../../../../assets/icons/moonIcon.svg";
import ascendantIcon from "../../../../assets/icons/ascendantIcon.svg";
import ketuIcon from "../../../../assets/icons/ketuIcon.svg";
import rahuIcon from "../../../../assets/icons/rahuIcon.svg";
import rahuKetuIcon from "../../../../assets/icons/rahuKetuIcon.svg";
import sunIcon from "../../../../assets/icons/sunIcon.svg";
import rayIcon from "../../../../assets/icons/rayIcon.svg";
import atmakarakaIcon from "../../../../assets/icons/atmakarakaIcon.svg";
import darakarakaIcon from "../../../../assets/icons/darakarakaIcon.svg";
import { CustomIcon } from "../../../../global/components";
import LoopIcon from "@mui/icons-material/Loop";

interface Symbols {
  [key: string]: string;
}

interface CustomProps {
  flipCardData: any;
}

const AnalysisFlipCards = (props: CustomProps) => {
  const classes = analysisStyles;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFlipped, setIsFlipped] = useState(false);

  const symbols: Symbols = {
    moon: moonIcon,
    sun: sunIcon,
    ascendant: ascendantIcon,
    rahu: rahuIcon,
    ketu: ketuIcon,
    d1Ascendant: ascendantIcon,
    d1Rahu: rahuKetuIcon,
    d10Rahu: rahuKetuIcon,
    ray: rayIcon,
    d1RahuBhava: rahuKetuIcon,
    daraKaraka: darakarakaIcon,
    atmaKaraka: atmakarakaIcon,
  };

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} height={"100%"} p={1}>
      <Stack
        direction={"row"}
        className={isFlipped ? "is-flipped" : ""}
        sx={classes.cardStyle}
        spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
        onClick={handleCardClick}
      >
        {!isFlipped &&
          (isMobile ? (
            <img
              src={symbols[props.flipCardData.symbol]}
              alt="Module not found..."
              style={{ width: "40px" }}
            />
          ) : (
            <img
              src={symbols[props.flipCardData.symbol]}
              alt="Module not found..."
              style={{ width: "80px" }}
            />
          ))}

        {!isFlipped ? (
          <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
            <Typography sx={classes.labelTypo}>
              {props.flipCardData.name}
            </Typography>
            <Divider sx={{ border: "1px solid #FFFFFF", width: "30%" }} />
            <Typography sx={classes.valueTypo}>
              {props.flipCardData.value}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={classes.valueTypo}>
                {props.flipCardData.zodiac}
              </Typography>
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
            </Box>
          </Stack>
        ) : (
          <Stack direction={"column"} spacing={1} sx={classes.flipCardContent}>
            <Typography sx={classes.flipLabelType}>
              {props.flipCardData.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
              <Typography sx={classes.valueTypo}></Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default AnalysisFlipCards;
