import {
  mediumFont,
  getRelativeFontSize,
  theme,
  regularFont,
  centerItemFlex,
} from "../../utils/styles";
import backImage from "../../assets/images/loginBackImage.png";
import "normalize.css";

const homeStyles = {
  content: {
    width: "100%",
    // top: "150px",
    // overflowX: "hidden",
    // position: "relative",
    minHeight: "calc(100vh - 165px)",
    height: "auto",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      minHeight: "calc(100vh - 100px)",
    },
    // backgroundColor: primaryBackgroundColor,
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    // p: 3,
    // height: "calc(100vh - 132px)",
    background: `url(${backImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // borderRadius: "20px",
    // border: "1px solid rgb(111 111 111 / 22%)",
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    // background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    marginTop: "25px",
    maxWidth: "1750px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      p: 1,
      // height: "calc(100vh - 138px)",
    },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 168px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    ...centerItemFlex,
    // display: "grid",
    // alignContent: "center",
    // width: "100%",
    // top: "150px",
    // overflowX: "hidden",
    // position: "relative",
    // height: "calc(100vh - 260px)",
    // [theme.breakpoints.down("sm")]: {
    //   top: "100px",
    //   height: "calc(100vh - 235px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 265px)",
    // },
  },
  // mainContainer: {
  //   p: 1,
  //   borderRadius: "20px",
  //   // height: "calc(100vh - 82px)",
  //   background: "rgb(103 103 103 / 15%)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(7px)",
  //   border: "1px solid rgb(255 255 255 / 17%)",
  //   [theme.breakpoints.down("sm")]: {
  //     p: 1,
  //     // height: "calc(100vh - 120px)",
  //   },
  //   // "@supports (-webkit-touch-callout: none)": {
  //   //   height: "calc(100vh - 150px)",
  //   // },
  // },
  logo: {
    display: "flex",
    marginTop: 10,
    mx: 4,
    [theme.breakpoints.down("md")]: {
      left: "35px",
    },
  },
  welcomeText: {
    // margin: "0 10%",
    ...regularFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(6),
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
      margin: "0 2%",
    },
  },
  contentWrapper: {
    // height: "calc(100vh - 270px)",
    // overflowY: "auto",
    // overflowX: "hidden",
    // "&::-webkit-scrollbar": {
    //   width: "5px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   borderRadius: "10px",
    //   border: "1px solid #FFFFFF",
    //   background: "#282945",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   height: "calc(100vh - 240px)",
    // },
  },
  italicTypo: {
    color: "#FFF",
    textAlign: "start",
    fontFamily: "jejuhallasan-regular",
    fontSize: "65px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.77px",
    textTransform: "uppercase",
    [theme.breakpoints.down("lg")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  cardStyle: {
    width: "100%",
    minWidth: "300px",
    display: "flex",
    justifyContent: "center",
    backdropFilter: "blur(5.5px)",
    background: "transparent",
    borderRadius: "20px",
    backgroundClip: "content-box,border-box",
    backgroundImage:
      "linear-gradient(#424451,#321751),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
    backgroundOrigin: "border-box",
    border: "double 2px transparent",
    [theme.breakpoints.down("sm")]: {
      minWidth: "0px",
    },
  },
  headerText: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(6),
    pl: 1,
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  signupBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#FDFDFD",
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(9),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #9053F6",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
      color: "#9053F6",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  loginBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#9053F6",
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(9),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  instructionBox: {
    borderRadius: "8px",
    background: "rgba(144, 83, 246, 0.28)",
    backdropFilter: "blur(5.5px)",
    py: 1.5,
    px: 6,
    [theme.breakpoints.down("sm")]: {
      px: 2,
    },
  },
  instructions: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  footer: {
    ...centerItemFlex,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    textAlign: "center",
    color: "#FFFFFF",
  },
  center: {
    ...centerItemFlex,
  },
} as const;

export default homeStyles;
