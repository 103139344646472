import {
  useMediaQuery,
  Box,
  Grid,
  Typography,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  Container,
} from "@mui/material";
import { theme } from "../../utils/styles";
import landingPageStyles from "./LandingPage.styles";
import { useAppSelector } from "../../utils/hooks";
import { selectAuthenticated } from "../../redux/authSlice";
import { Location } from "history";
import history from "../../utils/history";
import urls from "../../global/constants/UrlConstants";
import strings from "../../global/constants/StringConstants";
import Login from "../Auth/Login";
import loginSideImage from "../../assets/images/loginSideImage.png";
import Register from "../Auth/Register";
import ResetPassword from "../Auth/ResetPassword";
import AstrologyLogo from "../../assets/icons/logo.svg";
import englishIcon from "../../assets/icons/englishIcon.svg";
import polskiIcon from "../../assets/icons/polskiIcon.svg";
import appLogo from "../../assets/icons/appLogo.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppHeader from "../Shared/AppHeader/AppHeader";
import DeleteAccount from "../Auth/DeleteAccount";
import AppFooter from "../Shared/AppFooter/AppFooter";

interface CustomProps {
  location?: Location;
}

const LandingPage = (props: CustomProps) => {
  const classes = landingPageStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language.toLowerCase() === "pl" ? i18n.language.toLowerCase() : "en"
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const location = props.location?.pathname?.split("/")[1].toLowerCase();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const staticImageComponent = () => {
    return (
      <Stack direction="column" alignItems="center">
        <img src={loginSideImage} alt="Module Not Found..." width={"80%"} />
      </Stack>
    );
  };

  const getComponentBasedOnURL = () => {
    switch (location) {
      case strings.LOGIN: {
        return <Login />;
      }
      case strings.REGISTER: {
        return <Register />;
      }
      case strings.SET_PASSWORD: {
        return <ResetPassword isSetPassword={true} />;
      }
      case strings.RESET_PASSWORD: {
        return <ResetPassword isSetPassword={false} />;
      }
      case strings.DELETE_ACCOUNT: {
        return <DeleteAccount />;
      }
      default: {
        return <Login />;
      }
    }
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getLanguageDropdown = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={{ md: 4, sm: 1, xs: 1 }}
        pt={2}
      >
        <a href="/">
          {isMobile ? (
            <img
              src={AstrologyLogo}
              alt="Module Not Found..."
              height={"50px"}
            />
          ) : (
            <img
              src={AstrologyLogo}
              alt="Module Not Found..."
              height={"80px"}
            />
          )}
        </a>
        <Box>
          <Select
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            sx={classes.textField}
            name="bloodGroup"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <MenuItem value={"en"} selected>
              <Stack direction="row" alignItems={"center"} spacing={1.5}>
                {isMobile ? (
                  <img
                    src={englishIcon}
                    height={"25px"}
                    alt="Module Not Found..."
                  />
                ) : (
                  <img
                    src={englishIcon}
                    height={"30px"}
                    alt="Module Not Found..."
                  />
                )}

                <Typography sx={classes.languageTypo}>English</Typography>
              </Stack>
            </MenuItem>
            <MenuItem value={"pl"}>
              <Stack direction="row" alignItems={"center"} spacing={1.5}>
                <img
                  src={polskiIcon}
                  height={"30px"}
                  alt="Module Not Found..."
                />
                <Typography sx={classes.languageTypo}>Polski</Typography>
              </Stack>
            </MenuItem>
          </Select>
        </Box>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              {/* {getLanguageDropdown()} */}
              {location === strings.DELETE_ACCOUNT && (
                <Container maxWidth="lg">
                  <Box sx={classes.instructionBox}>
                    <Box sx={classes.instructions} display="inline-block">
                      "{t("deleteAccountContent1")}
                      <Typography
                        display="inline-block"
                        sx={classes.bookName}
                        ml={1}
                      >
                        {t("deleteAccountContent2")}
                      </Typography>
                      <img
                        src={appLogo}
                        style={{
                          position: "relative",
                          top: "9px",
                          padding: "0px 8px",
                        }}
                        alt="Module Not Found..."
                      />
                      {t("deleteAccountContent3")}"
                    </Box>
                  </Box>
                </Container>
              )}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  xl={4}
                  px={{ md: 4, sm: 1, xs: 1 }}
                >
                  {/* <Divider
                    sx={{
                      border: "1px solid #FFFFFF",
                      width: "100%",
                      height: "0px",
                      marginTop: "10px",
                    }}
                  /> */}
                  {getComponentBasedOnURL()}
                </Grid>
                {isDesktop && (
                  <Grid item lg={7} xl={8} sx={classes.loginImgBox}>
                    {staticImageComponent()}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getLandingPage = () => {
    return (
      <>
        <AppHeader toggleDrawer={toggleDrawer}></AppHeader>
        <Box>{getContent()}</Box>
        {/* <Box py={1.5}>{getFooter()}</Box> */}
        <AppFooter />
      </>
    );
  };

  if (isAuthenticated) {
    if (
      props.location?.pathname?.split("/")[1].toLowerCase() !==
      strings.RESET_PASSWORD
    ) {
      history.push(urls.dashboardViewPath);
      return null;
    }
  }
  return getLandingPage();
};

export default LandingPage;
