import urls from "../../global/constants/UrlConstants";
import {
  getUserTokenCallParams,
  getNoAuthCallParams,
  makeCall,
  getCallParams,
} from "../../utils/service";

export async function login(email: string, password: string) {
  const body = { email, password };
  try {
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.loginUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const registerUser = async (user: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", user);
    const response: any = await makeCall(urls.registerUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const verifyOtp = async (token: string, otp: string) => {
  try {
    const callParams = await getUserTokenCallParams("POST", token, { otp });
    const response: any = await makeCall(urls.verifyOtp, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const resendOtp = async (token: string, email: string) => {
  try {
    const callParams = await getUserTokenCallParams("POST", token, { email });
    const response: any = await makeCall(urls.resendOtp, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const setPassword = async (token: string, password: any) => {
  try {
    const callParams = await getUserTokenCallParams("POST", token, password);
    const response: any = await makeCall(urls.setPassword, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    const callParams = await getNoAuthCallParams("POST", { email });
    const response: any = await makeCall(urls.resetPassword, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteAccount = async (email: string, password: string) => {
  const body = { email, password };
  try {
    const callParams = await getNoAuthCallParams("DELETE", body);
    const response: any = await makeCall(urls.deleteAccount, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const setLanguagePreference = async (language: string) => {
  const url = `${urls.setLanguagePreference}/${language}`;
  try {
    const callParams = await getCallParams("PUT");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
