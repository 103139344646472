import {
  mediumFont,
  getRelativeFontSize,
  theme,
  centerItemFlex,
  boldFont,
  regularFont,
} from "../../utils/styles";
import backImage from "../../assets/images/loginBackImage.png";

const landingPageStyles = {
  // mainContainer: {
  //   p: 1,
  //   borderRadius: "20px",
  //   height: "calc(100vh - 82px)",
  //   background: "rgb(103 103 103 / 15%)",
  //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  //   backdropFilter: "blur(7px)",
  //   border: "1px solid rgb(255 255 255 / 17%)",
  //   [theme.breakpoints.down("sm")]: {
  //     p: 1,
  //     height: "calc(100vh - 120px)",
  //   },
  //   "@supports (-webkit-touch-callout: none)": {
  //     height: "calc(100vh - 150px)",
  //   },
  // },
  // mainBox: {
  //   p: 3,
  //   height: "calc(100vh - 132px)",
  //   background: `url(${backImage})`,
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  //   borderRadius: "20px",
  //   border: "1px solid rgb(111 111 111 / 22%)",
  //   [theme.breakpoints.down("sm")]: {
  //     p: 1,
  //     height: "calc(100vh - 138px)",
  //   },
  //   "@supports (-webkit-touch-callout: none)": {
  //     height: "calc(100vh - 168px)",
  //   },
  // },
  content: {
    width: "100%",
    // top: "150px",
    // overflowX: "hidden",
    // position: "relative",
    minHeight: "calc(100vh - 165px)",
    height: "auto",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
      minHeight: "calc(100vh - 100px)",
    },
    // backgroundColor: primaryBackgroundColor,
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    // p: 3,
    // height: "calc(100vh - 132px)",
    background: `url(${backImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // borderRadius: "20px",
    // border: "1px solid rgb(111 111 111 / 22%)",
    p: 1,
    borderRadius: "20px",
    minHeight: "calc(100vh - 220px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    // background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    marginTop: "25px",
    maxWidth: "1750px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      p: 1,
      // height: "calc(100vh - 138px)",
    },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 168px)",
    // },
  },
  subBox: {
    p: 3,
    minHeight: "calc(100vh - 260px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    // ...centerItemFlex,
    // display: "grid",
    // alignContent: "center",
    // width: "100%",
    // top: "150px",
    // overflowX: "hidden",
    // position: "relative",
    // height: "calc(100vh - 260px)",
    // [theme.breakpoints.down("sm")]: {
    //   top: "100px",
    //   height: "calc(100vh - 235px)",
    // },
    // "@supports (-webkit-touch-callout: none)": {
    //   height: "calc(100vh - 265px)",
    // },
  },
  icon: {
    fill: "#FFFFFF",
  },
  textField: {
    maxWidth: "160px",
    width: "100%",
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
    },
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 8px",
      },

      "&::placeholder": {
        ...mediumFont,
        color: "#FFFFFFA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  languageTypo: {
    fontSize: getRelativeFontSize(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  loginImgBox: {
    ...centerItemFlex,
    // height:"100vh",
    width: "100%",
  },
  instructionBox: {
    borderRadius: "8px",
    background: "rgba(144, 83, 246, 0.28)",
    backdropFilter: "blur(5.5px)",
    py: 1.5,
    px: 6,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      px: 2,
    },
  },
  instructions: {
    ...regularFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  bookName: {
    ...boldFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    borderBottom: "1px solid #FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  footer: {
    ...centerItemFlex,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    textAlign: "center",
    color: "#FFFFFF",
  },
} as const;

export default landingPageStyles;
