import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import analysisStyles from "../../Analysis.styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const RaysLegends = () => {
  const classes = analysisStyles;
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState({
    Will: false,
    Love: false,
    Intelligence: false,
    Harmony: false,
    Knowledge: false,
    Devotion: false,
    Magic: false,
  });

  const getHeader = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("soulRayLegend")}
        </Typography>
        <Typography sx={classes.labelTypo}>{t("soulRayLegendDesc")}</Typography>
      </Stack>
    );
  };

  const getWillRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("willRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("willRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("willRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("willRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("willRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>{t("willRayDesc1")}</Typography>
        {readMore.Will ? (
          <>
            <Typography sx={classes.pointsTypo} pt={1}>
              {t("willRayPoint1")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint2")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint3")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint4")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint5")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint6")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint7")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint8")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint9")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint10")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("willRayPoint11")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("willRayPoint12")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("willRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("willRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>{t("willRayDesc2")}</Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Will: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Will: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getLoveRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("loveRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("loveRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("loveRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("loveRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("loveRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>{t("loveRayDesc1")}</Typography>
        {readMore.Love ? (
          <>
            <Typography sx={classes.labelTypo} py={1}>
              {t("loveRayDesc2")}
            </Typography>

            <Grid container>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayStrength9")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("loveRayStrength10")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses9")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("loveRayWeaknesses10")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("loveRayWeaknesses11")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint1")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint2")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint3")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint4")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint5")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint6")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint7")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint8")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint9")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint10")}
            </Typography>
            <Typography sx={classes.pointsTypo}>
              {t("loveRayPoint11")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("loveRayPoint12")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("loveRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("loveRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>{t("loveRayDesc3")}</Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Love: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Love: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getIntelligenceRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("intelligenceRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("intelligenceRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("intelligenceRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("intelligenceRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("intelligenceRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>
          {t("intelligenceRayDesc1")}
        </Typography>
        {readMore.Intelligence ? (
          <>
            <Typography sx={classes.labelTypo} py={1}>
              {t("intelligenceRayDesc2")}
            </Typography>
            <Typography sx={classes.labelTypo} pb={1}>
              {t("intelligenceRayDesc3")}
            </Typography>

            <Grid container>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("intelligenceRayStrength9")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("intelligenceRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("intelligenceRayWeaknesses7")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.labelTypo}>
              {t("intelligenceRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("intelligenceRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("intelligenceRayDesc4")}
            </Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Intelligence: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Intelligence: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getHarmonyRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("harmonyRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("harmonyRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("harmonyRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("harmonyRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("alternateColor")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("harmonyRayAlternateColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("harmonyRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo} pb={1}>
          {t("harmonyRayDesc1")}
        </Typography>
        <Typography sx={classes.labelTypo}>{t("harmonyRayDesc2")}</Typography>

        {readMore.Harmony ? (
          <>
            <Grid container pt={1}>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("harmonyRayStrength9")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("harmonyRayWeaknesses8")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("harmonyRayWeaknesses9")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.labelTypo}>
              {t("harmonyRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("harmonyRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("harmonyRayDesc3")}
            </Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Harmony: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Harmony: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getKnowledgeRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("knowledgeRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("knowledgeRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("knowledgeRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("knowledgeRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("knowledgeRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>{t("knowledgeRayDesc1")}</Typography>

        {readMore.Knowledge ? (
          <>
            <Typography sx={classes.labelTypo} py={1}>
              {t("knowledgeRayDesc2")}
            </Typography>

            <Grid container>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayStrength9")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("knowledgeRayStrength10")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("knowledgeRayWeaknesses8")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("knowledgeRayWeaknesses9")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.labelTypo}>
              {t("knowledgeRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("knowledgeRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("knowledgeRayDesc3")}
            </Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Knowledge: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Knowledge: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getDevotionRay = () => {
    return (
      <Stack direction="column" px={2} py={3} sx={classes.legendContainer}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("devotionRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("devotionRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("devotionRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("devotionRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("devotionRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>{t("devotionRayDesc1")}</Typography>
        {readMore.Devotion ? (
          <>
            <Typography sx={classes.labelTypo} py={1}>
              {t("devotionRayDesc2")}
            </Typography>

            <Grid container>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayStrength9")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("devotionRayStrength10")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses9")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses10")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("devotionRayWeaknesses11")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("devotionRayWeaknesses12")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.labelTypo}>
              {t("devotionRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("devotionRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>
              {t("devotionRayDesc3")}
            </Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Devotion: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Devotion: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getMagicRay = () => {
    return (
      <Stack direction="column" px={2} py={3}>
        <Typography sx={classes.valueTypo} pb={2}>
          {t("magicRayHeader")}
        </Typography>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("color")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("magicRayColor")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("frequency")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("magicRayFrequency")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block">
          {t("wavelength")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("magicRayWavelength")}
          </Typography>
        </Box>
        <Box sx={classes.pointsTypo} display="inline-block" pb={1}>
          {t("pairsWith")} :
          <Typography display="inline-block" sx={classes.labelTypo} pl={0.5}>
            {t("magicRayPairsWith")}
          </Typography>
        </Box>
        <Typography sx={classes.labelTypo}>{t("magicRayDesc1")}</Typography>

        {readMore.Magic ? (
          <>
            <Grid container pt={1}>
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("strength")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayStrength9")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("magicRayStrength10")}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={classes.verticalDivider}
              />
              <Grid
                item
                xl={5.9}
                lg={5.9}
                md={12}
                sm={12}
                xs={12}
                px={{ lg: 2, xs: 0 }}
                // sx={classes.centerGrid}
              >
                <Typography sx={classes.subHeaderTypo} pb={1}>
                  {t("weaknesses")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses1")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses2")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses3")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses4")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses5")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses6")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses7")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses8")}
                </Typography>
                <Typography sx={classes.bulletTypo}>
                  {t("magicRayWeaknesses9")}
                </Typography>
                <Typography sx={classes.bulletTypo} pb={1}>
                  {t("magicRayWeaknesses10")}
                </Typography>
              </Grid>
            </Grid>

            <Typography sx={classes.labelTypo}>
              {t("magicRayProfessionDesc")}
            </Typography>
            <Typography sx={classes.pointsTypo} pb={1}>
              {t("magicRayProfessions")}
            </Typography>
            <Typography sx={classes.labelTypo}>{t("magicRayDesc2")}</Typography>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Magic: false })}
            >
              {t("readLess")}...
            </Typography>
          </>
        ) : (
          <>
            <Typography
              sx={classes.readMoreTypo}
              onClick={() => setReadMore({ ...readMore, Magic: true })}
            >
              {t("readMore")}...
            </Typography>
          </>
        )}
      </Stack>
    );
  };

  const getRaysLegends = () => {
    return (
      <Stack direction={"column"}>
        {getHeader()}
        {getWillRay()}
        {getLoveRay()}
        {getIntelligenceRay()}
        {getHarmonyRay()}
        {getKnowledgeRay()}
        {getDevotionRay()}
        {getMagicRay()}
      </Stack>
    );
  };

  return getRaysLegends();
};

export default RaysLegends;
