import {
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  theme,
} from "../../../utils/styles";

const customInputStyles = {
  // textField: {
  //   width: "99%",
  //   background: "transparent",
  //   boxShadow: "none",
  //   ".MuiOutlinedInput-notchedOutline": { border: 0 },
  //   "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
  //     border: 0,
  //   },
  //   "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //     border: 0,
  //   },

  //   "& .MuiInputBase-input": {
  //     border: "1px solid #FFFFFF",
  //     position: "relative",
  //     padding: "12px 12px",
  //     color: "#FFFFFF",

  //     "&::placeholder": {
  //       ...mediumFont,
  //       color: "#FFFFFFA6",
  //     },
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     backgroundClip: "content-box,border-box",
  //     backgroundImage:
  //       "linear-gradient(#060b25,#060b25),linear-gradient(90deg,#DF66E3 -0.11%,#5E75FF 55.68%, #2CF1DF 100.01%)",
  //     backgroundOrigin: "border-box",
  //     background: "rgba(255, 255, 255, 0.07)",
  //     border: "1px solid #FFFFFF",
  //     backdropFilter: "blur(5.5px)",
  //     borderRadius: "10px",
  //     "&.Mui-focused fieldset": {
  //       border: "none",
  //     },
  //   },
  //   "& .MuiFormHelperText-root.Mui-error": {
  //     fontSize: getRelativeFontSize(1),
  //     ...mediumFont,
  //   },
  // },
  textField: {
    width: "100%",
    borderRadius: "12px",
    ".MuiOutlinedInput-notchedOutline": { border: "1px solid #FFFFFF" },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #FFFFFF",
    },
    "& .MuiInputBase-input": {
      // border: "1px solid #FFFFFF",
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "10px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      background: "rgba(255, 255, 255, 0.05)",
      backdropFilter: "blur(5.5px)",
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        border: "1px solid #FFFFFF",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: getRelativeFontSize(1),
      ...mediumFont,
    },
  },
  nameField: {
    color: "#FFFFFF",
    marginLeft: "6px",
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
} as const;

export default customInputStyles;
