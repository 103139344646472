import { Route, Redirect, RouteProps } from "react-router-dom";
import PageNotFound from "../../../screens/PageNotFound/PageNotFound";
import { doesUserHasAccessTo } from "../../../utils/AuthorizationManager";

interface CustomProps extends RouteProps {
  component?: any;
  isLoggedIn: boolean;
  isSubscribed?: boolean;
  componentName: string;
}

const PrivateRoute = (props: CustomProps) => {
  const {
    component: Component,
    isLoggedIn,
    isSubscribed,
    componentName,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn ? (
          doesUserHasAccessTo(componentName) ? (
            isSubscribed ? (
              <Component {...routeProps} />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: routeProps.location,
                    search: routeProps.location.search,
                  },
                }}
              />
            )
          ) : (
            <PageNotFound />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: routeProps.location,
                search: routeProps.location.search,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
