import urls from "../../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../../utils/service";

export const deletePaymentMethod = async (paymentMethodId: string) => {
  const url = `${urls.deletePaymentMethod}/${paymentMethodId}`;
  try {
    const callParams = await getCallParams("DELETE");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
