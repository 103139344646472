import {
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import appFooterStyles from "./AppFooter.styles";
import AstrologyLogo from "../../../assets/icons/logo.svg";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../utils/hooks";
import { selectAuthenticated } from "../../../redux/authSlice";
import urls from "../../../global/constants/UrlConstants";
import callingIcon from "../../../assets/icons/callingIcon.svg";
import emailIcon from "../../../assets/icons/emailIcon.svg";
import addressIcon from "../../../assets/icons/addressIcon.svg";
import appStoreIcon from "../../../assets/icons/appStoreIcon.svg";
import playStoreIcon from "../../../assets/icons/playStoreIcon.svg";
import history from "../../../utils/history";
import ContactUsDialog from "../Dialogs/ContactUsDialog/ContactUsDialog";
import { useState } from "react";

const AppFooter = () => {
  const classes = appFooterStyles;
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getAstrologyLogo = () => {
    return (
      <Box sx={{ cursor: "pointer", textAlign: { md: "start", xs: "center" } }}>
        <a href={isAuthenticated ? urls.dashboardViewPath : urls.homeViewPath}>
          <img src={AstrologyLogo} alt="Module Not Found..." height={"50px"} />
        </a>
      </Box>
    );
  };

  const getLinks = () => {
    return (
      <Box sx={{ display: "grid", justifyContent: "center" }}>
        <Box sx={classes.headBox}>
          <Typography sx={classes.headTypo}>Quick Links</Typography>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          pt={2}
          alignItems={{ md: "start", xs: "center" }}
        >
          <Typography
            sx={classes.linkTypo}
            onClick={() => {
              isAuthenticated
                ? history.push(urls.dashboardViewPath)
                : history.push(urls.homeViewPath);
            }}
          >
            Home
          </Typography>
          {isAuthenticated && (
            <Typography sx={classes.linkTypo} onClick={() => setOpen(true)}>
              Contact Us
            </Typography>
          )}

          <Typography
            sx={classes.linkTypo}
            onClick={() => history.push(urls.privacyPolicyViewPath)}
          >
            Privacy Policy
          </Typography>
        </Stack>
      </Box>
    );
  };

  const getContactInfo = () => {
    return (
      <Box sx={{ display: "grid", justifyContent: "center" }}>
        <Box sx={classes.headBox}>
          <Typography sx={classes.headTypo}>Contact</Typography>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          pt={2}
          alignItems={{ md: "start", xs: "center" }}
        >
          {/* <Stack direction={"row"} spacing={1}>
            <img src={callingIcon} />
            <Typography sx={classes.contentTypo}>:</Typography>
            <a href="tel:+91 9033742651" style={{ textDecoration: "auto" }}>
              <Typography sx={classes.linkTypo}>+91 9033742651</Typography>
            </a>
          </Stack> */}
          <Stack direction={"row"} spacing={1}>
            <img src={emailIcon} />
            <Typography sx={classes.contentTypo}>:</Typography>
            <a
              href="mailto:info@xiegawodnika.com"
              style={{ textDecoration: "auto" }}
            >
              <Typography sx={classes.linkTypo}>
                info@xiegawodnika.com
              </Typography>
            </a>
          </Stack>
          {/* <Stack direction={"row"} spacing={1}>
            <img src={addressIcon} width={"15px"} />
            <Typography sx={classes.contentTypo}>:</Typography>
            <Typography sx={classes.contentTypo}>Poland</Typography>
          </Stack> */}
          <Stack direction={"row"} spacing={1} pt={2}>
            <Box
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.akariaryaca.xiegawodnika"
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <img src={playStoreIcon} />
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const getFooterContent = () => {
    return (
      <Grid container pb={2} spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          {getAstrologyLogo()}
          <Typography sx={classes.contentTypo} pt={2}>
            {t("homeContent")}
          </Typography>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          {getLinks()}
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          {getContactInfo()}
        </Grid>
      </Grid>
    );
  };

  const getCopyrights = () => {
    return (
      <Box pt={2}>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography sx={classes.footerTypo}>
            &copy; {getYear()} {t("footer")}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getAppFooter = () => {
    return (
      <Box sx={classes.footer}>
        <Container maxWidth="lg">
          {getFooterContent()}
          <Divider sx={{ border: "1px solid #ffffff59" }} />
          {getCopyrights()}
          <ContactUsDialog
            setIsLoading={setIsLoading}
            setOpenContactUs={setOpen}
            handleClose={handleClose}
            openContactUs={open}
          />
        </Container>
      </Box>
    );
  };

  return getAppFooter();
};

export default AppFooter;
