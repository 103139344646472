import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";
import { ProfileDetails } from "../models/interfaces";

export interface ProfilesState {
  email: string;
  profiles: ProfileDetails[];
}

const initialState: ProfilesState = {
  email: "",
  profiles: [],
};

export const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    storeProfileDetails: (state, action: PayloadAction<ProfilesState>) => {
      const { email, profiles } = action.payload;
      state.email = email;

      profiles.forEach((newProfile) => {
        const existingProfileIndex = state.profiles.findIndex(
          (profile) => profile.profileId === newProfile.profileId
        );

        if (existingProfileIndex !== -1) {
          state.profiles[existingProfileIndex] = {
            ...state.profiles[existingProfileIndex],
            ...newProfile,
          };
        } else {
          state.profiles.push(newProfile);
        }
      });
    },
  },
});

export const { storeProfileDetails } = profilesSlice.actions;

export const selectProfileDetails = (state: RootState) =>
  state.profiles.profiles;
export const selectStoredEmail = (state: RootState) => state.profiles.email;

export default profilesSlice.reducer;
