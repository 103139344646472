import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import analysisStyles from "../../Analysis.styles";
import { theme } from "../../../../utils/styles";
import aquariusIcon from "../../../../assets/icons/ZodiacSigns/aquariusIcon.png";
import ariesIcon from "../../../../assets/icons/ZodiacSigns/ariesIcon.png";
import cancerIcon from "../../../../assets/icons/ZodiacSigns/cancerIcon.png";
import capricornsIcon from "../../../../assets/icons/ZodiacSigns/capricornsIcon.png";
import geminiIcon from "../../../../assets/icons/ZodiacSigns/geminiIcon.png";
import leoIcon from "../../../../assets/icons/ZodiacSigns/leoIcon.png";
import libraIcon from "../../../../assets/icons/ZodiacSigns/libraIcon.png";
import piscesIcon from "../../../../assets/icons/ZodiacSigns/piscesIcon.png";
import scorpioIcon from "../../../../assets/icons/ZodiacSigns/scorpioIcon.png";
import sagittariusIcon from "../../../../assets/icons/ZodiacSigns/sagittariusIcon.png";
import taurusIcon from "../../../../assets/icons/ZodiacSigns/taurusIcon.png";
import virgoIcon from "../../../../assets/icons/ZodiacSigns/virgoIcon.png";
import { CustomIcon } from "../../../../global/components";
import LoopIcon from "@mui/icons-material/Loop";

interface Symbols {
  [key: string]: string;
}

interface CustomProps {
  flipCardData: any;
}

const MineralsFlipCards = (props: CustomProps) => {
  const classes = analysisStyles;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFlipped, setIsFlipped] = useState(false);

  const symbols: Symbols = {
    Aquarius: aquariusIcon,
    Leo: leoIcon,
    Aries: ariesIcon,
    Capricorn: capricornsIcon,
    Cancer: cancerIcon,
    Pisces: piscesIcon,
    Scorpio: scorpioIcon,
    Gemini: geminiIcon,
    Libra: libraIcon,
    Taurus: taurusIcon,
    Virgo: virgoIcon,
    Sagittarius: sagittariusIcon,
  };

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <Grid item xl={12} lg={6} md={6} sm={12} xs={12} height={"100%"}>
      <Stack
        direction={"row"}
        className={isFlipped ? "is-flipped" : ""}
        sx={classes.mineralsCardStyle}
        spacing={{ xl: 2, lg: 5, md: 4, sm: 3, xs: 1 }}
        onClick={handleCardClick}
      >
        {!isFlipped &&
          (isMobile ? (
            <img
              src={symbols[props.flipCardData.name]}
              alt="Module not found..."
              style={{ width: "40px" }}
            />
          ) : (
            <img
              src={symbols[props.flipCardData.name]}
              alt="Module not found..."
              style={{ width: "auto" }}
            />
          ))}

        {!isFlipped ? (
          <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
            <Typography sx={classes.valueTypo}>
              {props.flipCardData.mineral}
            </Typography>
            <Divider sx={{ border: "1px solid #FFFFFF", width: "30%" }} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={classes.labelTypo}>
                {props.flipCardData.name} - {props.flipCardData.number}
              </Typography>
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
            </Box>
          </Stack>
        ) : (
          <Stack direction={"column"} spacing={1} sx={classes.flipCardContent}>
            <Typography sx={classes.flipLabelType}>
              {props.flipCardData.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <CustomIcon
                icon={
                  <LoopIcon sx={{ fontSize: "20px" }} htmlColor="#FFFFFF" />
                }
              />
              <Typography sx={classes.valueTypo}></Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default MineralsFlipCards;
