import {
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const customTableStyles = {
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    borderTopRadius: "15px",
    borderBottomRadius: "15px",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      overflow: "auto",
    },
  },
  tableBody: {
    maxHeight: "500px",
    overflow: "auto",
  },
  tableHeaderCell: {
    ...mediumFont,
    color: "#FFFFFF",
    backgroundColor: "#9053F6",
    // borderTop: "1px solid #F0F0F0",
    // borderBottom: "1px solid #F0F0F0",
    border: "none",
    textAlign: "left",
    "& .MuiTableSortLabel-icon": {
      display: "none",
    },
  },
  tableCell: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    height: "25px",
    // borderTop: "1px solid #F0F0F0",
    // borderBottom: "1px solid #F0F0F0",
    border: "none",
    textAlign: "left",
    color: "#FFFFFF",
  },
  tableRow: {
    margin: "10px 0",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkBoxStyle: {
    paddingRight: "50px",
  },
  checkbox: {
    color: "#ffffff",
  },
  mediumFonts: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(5),
  },
} as const;

export default customTableStyles;
