import {
  boldFont,
  centerItemFlex,
  customButtonStyle,
  disabledBackgroundColor,
  getRelativeFontSize,
  mediumFont,
  theme,
} from "../../../utils/styles";

const customButtonStyles = {
  // btnStyle: {
  //   ...customButtonStyle,
  //   ...mediumFont,
  //   width: "100%",
  //   padding: "10px 20px",
  //   ...centerItemFlex,
  //   "&:disabled": {
  //     background: "#FFFFFF",
  //     border: "1px solid",
  //     boxShadow: "0px 3px 17px rgba(0, 0, 0, 0.07)",
  //     borderRadius: "14px",
  //     color: "#f15e22",
  //     "&:hover": {
  //       background: "#FFFFFF",
  //     },
  //   },
  // },

  // backgroundImage:
  //   "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
  // borderRadius: "8px",
  // boxSizing: "border-box",
  // display: "block",
  // height: "54px",
  // letterSpacing: "1px",
  // margin: "0 10px",
  // padding: "4px",
  // position: "relative",
  // textDecoration: "none",
  // textTransform: "uppercase",
  // zIndex: 2,

  // "&.hover": {
  //   color: "#fff",
  // },

  // "& .btnText": {
  //   alignItems: "center",
  //   borderRadius: "8px",
  //   display: "flex",
  //   justifyContent: "center",
  //   height: "100%",
  //   transition: "background .5s ease",
  //   width: "100%",
  //   ...boldFont,
  //   color:
  //     "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
  //   background:
  //     "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
  //   backgroundClip: "text",
  //   WebkitBackgroundClip: "text",
  //   WebkitTextFillColor: "transparent",
  //   fontSize: getRelativeFontSize(4),
  //   [theme.breakpoints.down("md")]: {
  //     fontSize: getRelativeFontSize(0),
  //   },
  // },

  primaryBtnStyle: {
    width: "100%",
    py: 1,
    borderRadius: "8px",
    background: "#9053F6",
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #FDFDFD",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
      color: "#FDFDFD",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  secondaryBtnStyle: {
    width: "100%",
    py: 1,
    borderRadius: "8px",
    background: "#FDFDFD",
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
    minWidth: "180px",
    textShadow: "0 1px 0 rgb(0 0 0 / 40%)",
    transition: "box-shadow 0.15s ease, transform 0.15s ease",
    willChange: "box-shadow,transform",

    "&:hover": {
      boxShadow:
        "0px 0.1em 0.2em rgb(45 35 66 / 40%), 0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -3px 0px #9053F6",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
      color: "#9053F6",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  glydeGif: {
    width: "15px",
    height: "15px",
  },
  loadingStyle: {
    color: "#000000",
    width: "25px !important",
    height: "25px !important",
  },
  disabled: {
    color: disabledBackgroundColor,
  },
};

export default customButtonStyles;
