import urls from "../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../utils/service";

export const getPaymentMethodDetails = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getPaymentMethods, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const makeDefaultPaymentMethod = async (paymentMethodId: string) => {
  const url = `${urls.makeDefaultPaymentMethod}/${paymentMethodId}/default`;
  try {
    const callParams = await getCallParams("PUT");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getPayPalUrl = async (priceId: string) => {
  try {
    const callParams = await getCallParams("POST", { priceId });
    const response = await makeCall(urls.getPayPalUrl, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
