import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const addProfile = async (profile: any) => {
  try {
    const callParams = await getCallParams("POST", profile);
    const response = await makeCall(urls.addProfile, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
