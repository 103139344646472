import urls from "../../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../../utils/service";

export const contactUs = async (reason: string, message: string) => {
  const obj = {
    reason,
    message,
  };
  try {
    const callParams = await getCallParams("POST", obj);
    const response = await makeCall(urls.contactUsApi, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
